import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Alumno} from '../alumno.model';
import {UserService} from '../../auth/user/user.service';
import {NgForm} from '@angular/forms';
import {AlumnoService} from '../alumno.service';


@Component({
  selector: 'app-alumno-edit',
  templateUrl: './alumno-edit.component.html',
  styleUrls: ['./alumno-edit.component.css']
})
export class AlumnoEditComponent implements OnInit {
  hijo = new Alumno(0, '', '', '', false, '');
  id: number;
  editMode= false;


  constructor(private inRouter: ActivatedRoute,
              private userService: UserService,
              private alumnoService: AlumnoService,
              private router: Router) {

  }

  onNew(f: NgForm) {
    this.hijo.id = this.id;
    this.hijo.nombre = f.value.nombre;
    this.hijo.apellidos = f.value.apellidos;
    console.log(f.value);
    this.hijo.birthday = f.value.birthday;
    this.hijo.rrss = f.value.rrss;
    this.hijo.nivel = this.alumnoService.calcularNivel(this.hijo);
    if (this.editMode) {
      // this.userService.hijos[this.id] = this.hijo;
      this.userService.guardaHijo(this.hijo);
    } else {
      this.hijo.padre = this.userService.uid;
      const idAlu = this.userService.newAlumno(this.hijo);
      this.userService.datos.hijos.push(idAlu);
      this.userService.saveUser();
    }
    console.log(this.userService.hijos);


  }

  ngOnInit() {

    this.inRouter.params
      .subscribe(
        (params: Params) => {
          if (!isNaN(+params['id'])) {
            this.editMode = true;
            this.id = +params['id'];
            this.hijo = this.userService.hijos[this.id];
          } else {
            this.id = this.userService.hijos.length;
          }
          // console.log(this.id + " " + this.editMode);

        }
      );


  }

}
