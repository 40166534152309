import {DireccionFacturacion} from '../facturas/direccion-facturacion.model';

export class Partner {
  public id: number
  public descripcion: string;
  public contacto: string;
  public direccion: string;
  public email: string;
  public telefono: string;
  public comentarios: string;
  public tipo: number;
  public datosFactura: DireccionFacturacion;
  public idUser: string;
  public facturacion: boolean;

  constructor(id: number, descripcion: string) {
    this.id =  id;
    this.descripcion = descripcion;
    this.tipo = 1;
    this.facturacion = false;

  }

}
