import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Horario} from './horario.model';
import {ActivatedRoute, Params} from '@angular/router';
import {CalendarService} from './calendar.service';
import {AlumnoService} from '../alumno.service';
import {UserService} from '../../auth/user/user.service';
import {AdminService} from '../../admin/admin.service';
import {Servicio} from '../../admin/servicios/servicio.model';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
  @Input() id: number ;
  @Input() idServicio = -1;
  @Input() horario: Horario;
  @Input() capacidad: number;
  @Output() daySelected = new EventEmitter<{ClassId: number}>();
  @Input() edit: boolean;
  servicio: Servicio;

  closeResult: string;

  constructor( private inRouter: ActivatedRoute,
               private calendarService: CalendarService,
               private alumnoService: AlumnoService,
               private userService: UserService,
               private adminService: AdminService) { }

  ngOnInit() {
    this.inRouter.params
      .subscribe(
        (params: Params) => {
          this.id = +params['id'];
        }
      );
    if ( this.idServicio >= 0 ) {
      this.servicio = this.adminService[this.idServicio]
    }
    // console.log(this.horario)
  }


  selectCourse(index) {
    this.daySelected.emit({ClassId: index});
  }

  verEstado(idClase: number) {
    let estilo = '';
    if (this.id >= 0 && !this.edit) {
      if (this.horario[idClase].activo) {
        estilo = 'visible '
      } else {
        estilo = 'invisible '
      }
      if ( this.alumnoService.activoClase( this.alumnoService.alumnos[this.id].key , idClase, this.idServicio, this.adminService.servicios[this.idServicio].puntual) ) {
        estilo += 'list-group-item-success';
      } else if (this.capacidad <= this.contarAlumnos(this.horario[idClase]) ) {
        estilo += 'list-group-item-danger';
      }
    } else if (this.edit && this.id >= -1) {
      if (this.horario[idClase].activo ) {
        estilo = 'list-group-item-success';
      } else {
        estilo = 'list-group-item-danger';

      }
    }
//    console.log(this.horario[idClase].activo)
    return estilo
  }

  colorAlumnos(idClase: number) {
    switch (this.contarAlumnos(this.horario[idClase])) {
      case 0:
        return 'badge-success'
      case 1:
      case 2:
        return 'badge-info'
      case 3:
      case 4:
      case 5:
        return 'badge-primary';
      case 6:
      case 7:
        return 'badge-warning'
      case 8:
        return 'badge-info'
    }
  }

  contarAlumnos(clase: Horario) {
    if (this.adminService.servicios[this.idServicio].puntual) {
      return this.adminService.servicios[this.idServicio].alumnos.length;
    } else {
      return this.alumnoService.disponibilidadTemporal(clase, this.adminService.servicios[this.idServicio]);
    }
  }
}
