export class Horario {
    public id: number;
    public dia: string;
    public hora: string;
    public alumnos: Array<string>;
    public activo: boolean;
    public idProfe: string;


    constructor(id: number, dia: string, hora: string, activo: boolean = false , profe: string = '') {
      this.id = id;
      this.dia = dia;
      this.hora = hora;
      this.alumnos = [] ;
      this.activo = activo;
      this.idProfe = profe;
    }

}
