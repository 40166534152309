export class Periodo {
  id: number;
  fechaInicio: string;
  fechaFin: string;
  descripcion: string;
  ejercicio: number;

  constructor(id: number, descripcion: string, ejercicio: number, fechaInicio: string, fechaFin: string ) {
    this.id = id;
    this.descripcion = descripcion;
    this.ejercicio = ejercicio;
    this.fechaInicio = fechaInicio;
    this.fechaFin = fechaFin ;
  }
}
