import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {PadreService} from '../padre.service';
import {User} from '../../../auth/user/user.model';
import {Location} from '@angular/common';
import {Alumno} from '../../../alumno/alumno.model';
import {AlumnoService} from '../../../alumno/alumno.service';
import {AdminService} from '../../admin.service';
import {Factura} from '../../facturas/factura.model';
import {LineaFactura} from '../../facturas/lineafactura.model';
import {SelectItem } from 'primeng/primeng';
import {Cobro} from '../../facturas/cobro.model';

@Component({
  selector: 'app-ficha-padre',
  templateUrl: './ficha-padre.component.html',
  styleUrls: ['./ficha-padre.component.css']
})
export class FichaPadreComponent implements OnInit {
  id: number;
  padre: User;
  hijos: Alumno[];
  serviciosPendientes: Array<LineaFactura> = [];
  facturaPendiente: Array<Factura>;
  facturasFiltradas: Array<Factura> = [];
  lineasFacturaFiltrada: Array<LineaFactura>;
  displayDialog: boolean;
  fops: SelectItem[];
  facturaSeleccionada = 0;
  cobroTemp: Cobro;
  cobrosFiltrados: Cobro[];
  lugares: SelectItem[];


  constructor(private inRouter: ActivatedRoute,
              private padreService: PadreService,
              private alumnoService: AlumnoService,
              private location: Location,
              private router: Router,
              private adminService: AdminService) { }

  ngOnInit() {
    this.padre = new User();
    this.hijos = [];
    this.fops = [];
    this.fops.push({label: 'Elije Forma de Pago', value: -1 });
    this.fops.push({label: 'Efectivo', value: 0 });
    this.fops.push({label: 'Tarjeta', value: 1 });
    this.fops.push({label: 'Transferencia', value: 2 });
    this.fops.push({label: 'Domiciliación', value: 3 });
    this.fops.push({label: 'Abono', value: 4 });
    this.cobrosFiltrados = [];
    if (!this.padreService.padres) {
      this.router.navigate(['/listapadres'])
      return false;
    }
    this.inRouter.params
      .subscribe(
        (params: Params) => {
          this.id = +params['id'];
          this.cargarPadre(this.id);
          this.buscarPendientes();
          this.buscarFacturas();
        }
      );
    this.padreService.padresUpdate.subscribe((padre: User) => this.cargarPadre(this.id));
    this.lugares = [];
    this.lugares.push( { label: 'Sin Servicio', value: -1 });
    for ( const pana of this.adminService.partners) {
      this.lugares.push( {label: pana.descripcion, value: pana.id});
    }
  }

  cargarPadre(id: number) {
    if (this.padreService.padres) {
      this.padre = this.padreService.padres[id];
      this.hijos = [];
      for (const keyHijo of this.padre.hijos) {
        this.hijos.push(this.alumnoService.alumnos.find(hijo => hijo.key === keyHijo))
      }
    }
  }

  goBack() {
    this.location.back();
  };

  facturar() {
    console.log(this.facturaPendiente[0])
    console.log(this.serviciosPendientes)
    this.adminService.creaFacturaFinal(this.facturaPendiente[0], this.serviciosPendientes);
    this.buscarPendientes();
    this.buscarFacturas();
  }

  buscarPendientes() {
    // console.log(this.adminService.preFacturas)
    this.serviciosPendientes = [];
    this.facturaPendiente = this.adminService.preFacturas.filter(factura => factura.idUsuario === this.padre.key);
    // console.log(this.facturaPendiente)
    for ( const factura  of this.facturaPendiente ) {
      this.serviciosPendientes = this.serviciosPendientes.concat(
        this.adminService.preLineasFactura.filter(
          linea => factura.idLineaFactura.indexOf(linea.id) !== -1));
    }
  }

  buscarFacturas() {
    this.lineasFacturaFiltrada = [];
    this.facturasFiltradas = this.adminService.facturas.filter(factura => factura.idUsuario === this.padre.key);
    // console.log(this.facturaPendiente)
    for ( const factura  of this.facturasFiltradas ) {
      this.lineasFacturaFiltrada = this.lineasFacturaFiltrada.concat(
        this.adminService.lineasFactura.filter(
        linea => factura.idLineaFactura.indexOf(linea.id) !== -1));
    }
  }

  onRowSelect(event) {
    this.facturaSeleccionada = this.facturasFiltradas.findIndex(fact => fact.id === event.data.id) ;
    this.cobroTemp = new Cobro(-1, this.facturasFiltradas[this.facturaSeleccionada].id,
      this.adminService.Cobrado(this.facturasFiltradas[this.facturaSeleccionada]));
    console.log(this.facturasFiltradas[this.facturaSeleccionada])
    this.cobrosFiltrados = this.adminService.cobros.filter(
      cob => this.facturasFiltradas[this.facturaSeleccionada].cobros.indexOf(cob.id) !== -1);
    this.displayDialog = true;
    //const userKey = this.padreService.padres.findIndex(dad => dad.key === event.data.key)
    //this.router.navigate(['/fichapadre', userKey]);

  }

  guardaCobro() {
    if ( this.cobroTemp.importe !== 0 ) {
      this.cobroTemp.id = this.adminService.cobros.length;
      this.adminService.cobros.push(this.cobroTemp);
      this.adminService.facturas[this.facturasFiltradas[this.facturaSeleccionada].id].cobros.push(this.cobroTemp.id);
      this.adminService.guardarObj(this.cobroTemp, 'facturacion/cobros');
      this.adminService.guardarObj(this.adminService.facturas[this.facturasFiltradas[this.facturaSeleccionada].id], 'facturacion/facturas');
      this.cobrosFiltrados = this.adminService.cobros.filter(
        cob => this.facturasFiltradas[this.facturaSeleccionada].cobros.indexOf(cob.id) !== -1);
    }
    this.displayDialog = false;
  }

  nombreAlumno(idAlu: string) {
    const alumno = this.alumnoService.alumnos.find(alu => alu.key === idAlu);
    return alumno.nombre + ' ' + alumno.apellidos;
  }

  mostrarLugares(aluKey: string) {
    const inscripciones = this.alumnoService.alumnos.find( alu => alu.key === aluKey).inscripciones;
    let respuesta = '';
    if (inscripciones) {
      for (const inscripcion of inscripciones) {
        const cole = this.adminService.partners[
          this.adminService.servicios[inscripcion.idServicio].idLugar].descripcion;
        if ( respuesta.indexOf(cole) === -1) {
          respuesta += cole + ' '
        }
      }
    }
    return respuesta;
  }

  onLugares(event, dt, col) {
    const values = [];
    if ( event.value.indexOf(-1) !== -1 ) {
      for (const al of this.alumnoService.alumnos.filter(alu => alu.inscripciones.length === 0 )) {
        values.push(al.key);
      }
    }
    for (const al of this.alumnoService.alumnos.filter(alu => alu.inscripciones.find(
      inscrip => event.value.indexOf( this.adminService.servicios[inscrip.idServicio].idLugar ) !== -1 ))) {
      values.push(al.key);
    }
    console.log(event.value.indexOf(-1))
    if ( values.length === 0 && ( event.value.length > 0 )) {
      values.push('');
    }
    dt.filter(values, col.field, col.filterMatchMode)
  }

  onHijoSelect(event) {
    const aluKey = this.alumnoService.alumnos.findIndex(alu => alu.key === event.data.key)
    this.router.navigate(['/fichaalumno', aluKey]);
  }
}
