import { Component, OnInit } from '@angular/core';
import {AdminService} from '../../admin.service';
import {ActivatedRoute, Params} from '@angular/router';
import {Location} from '@angular/common';
import {Servicio} from '../servicio.model';
import {SelectItem} from 'primeng/primeng';
import {PadreService} from '../../padres/padre.service';
import {AlumnoService} from '../../../alumno/alumno.service';
import {Horario} from '../../../alumno/calendar/horario.model';

@Component({
  selector: 'app-ficha-servicio',
  templateUrl: './ficha-servicio.component.html',
  styleUrls: ['./ficha-servicio.component.css']
})
export class FichaServicioComponent implements OnInit {

  id: number;
  servicio: Servicio;
  activo = false ;
  clase= 0 ;
  hora = '';
  profes: SelectItem[];
  profeSelected: string;
  displayDialog: boolean;

  constructor(private inRouter: ActivatedRoute,
              public adminService: AdminService,
              private location: Location,
              private padreService: PadreService,
              private alumnoService: AlumnoService) {
    this.profes = [];
    this.profes.push({label: 'Indicar Profesor', value: ''});
    for ( const profe of this.padreService.padres.filter( user => user.idPermiso > 2 ) ){
      this.profes.push({label: profe.nombre + ' ' + profe.apellidos, value: profe.key});
    }
  }

  ngOnInit() {
    this.profeSelected = '';
    this.inRouter.params
      .subscribe(
        (params: Params) => {
          this.id = +params['id'];
          this.servicio = this.adminService.servicios[this.id];
          if (params['hora'] >= 0) {
            this.onDaySelect({ClassId: +params['hora']});
          }
        }
      );

  }

  goBack() {
    this.location.back();
  }

  onDaySelect(classData: {ClassId: number}) {
    this.clase = classData.ClassId;
    this.hora = this.servicio.horario[this.clase].hora;
    this.activo = this.servicio.horario[this.clase].activo;
    this.profeSelected = (this.servicio.horario[this.clase].idProfe) ? this.servicio.horario[this.clase].idProfe : '';
    this.displayDialog = true;
  }

  guardarCambios() {
    this.servicio.horario[this.clase].activo = this.activo;
    this.servicio.horario[this.clase].hora = this.hora;
    this.servicio.horario[this.clase].idProfe = this.profeSelected;
    this.adminService.guardarObj(this.servicio, 'servicios');
    this.displayDialog = false;
  }

  alumnosApuntados(clase: Horario, servicio) {
    return this.alumnoService.disponibilidadTemporal( clase, servicio)
  }
}
