import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {Location} from '@angular/common';
import {AdminService} from '../../admin.service';
import {Servicio} from '../servicio.model';
import {NgForm} from '@angular/forms';
import {SelectItem} from 'primeng/primeng';

@Component({
  selector: 'app-form-servicio',
  templateUrl: './form-servicio.component.html',
  styleUrls: ['./form-servicio.component.css']
})
export class FormServicioComponent implements OnInit {
  id: number;
  servicio: Servicio;
  partners: SelectItem[];
  niveles: SelectItem[];

  constructor(private inRouter: ActivatedRoute,
              private location: Location,
              public adminService: AdminService) { }

  ngOnInit() {
    this.loadPartners();
    this.niveles = this.adminService.niveles;
    this.adminService.partnerUpdate.subscribe( partners => this.loadPartners() );
    this.inRouter.params
      .subscribe(
        (params: Params) => {
          this.id = +params['id'];
          if (this.id < 0) {
            this.servicio = new Servicio(this.adminService.servicios.length, '', 0);
            //console.log(this.servicio)
          } else {
            this.servicio = this.adminService.servicios[this.id];
          }

        }
      );
  }

  goBack() {
    this.location.back();
  }

   formSent(f: NgForm) {
    console.log(f)
    if (f.value.lugar) {
      this.servicio.idLugar = f.value.lugar
    }
    this.adminService.guardarObj(this.servicio, 'servicios');
    this.goBack();
  }

  loadPartners() {
    this.partners = [];
    this.partners.push({label: 'Selecciona un Centro     ', value: -1})
    for ( const pana of this.adminService.partners ) {
      this.partners.push({label: pana.descripcion, value: pana.id})
    }
  }

}

//
