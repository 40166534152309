import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

import { HeaderComponent } from './header/header.component';
import { DropdownDirective } from './shared/dropdown.directive';
import { AppRoutingModule } from './app-routing.module';
import { DataStorageService } from './shared/data-storage.service';
import { SigninComponent } from './auth/signin/signin.component';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './auth/auth-guard.service';
import { AppComponent } from 'app/app.component';
import { UserComponent } from './auth/user/user.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import {UserService} from './auth/user/user.service';
import {
  AccordionModule, AutoCompleteModule,
  ButtonModule, ChartModule, CheckboxModule, ConfirmationService, ConfirmDialogModule, DataTableModule, DropdownModule, GrowlModule,
  InputSwitchModule,
  InputTextModule,
  MenuModule,
  MessagesModule,
  MultiSelectModule,
  PanelModule,
  PasswordModule, TabViewModule
} from 'primeng/primeng';
import {ToastModule} from 'primeng/toast';
import {DialogModule} from 'primeng/components/dialog/dialog';
import { RegisterComponent } from './auth/register/register.component';
import { AlumnoComponent } from './alumno/alumno.component';
import { AlumnoEditComponent } from './alumno/alumno-edit/alumno-edit.component';
import { AlumnoStartComponent } from './alumno/alumno-start/alumno-start.component';
import {CalendarModule} from 'primeng/components/calendar/calendar';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AlumnoService} from './alumno/alumno.service';
import { CalendarComponent } from './alumno/calendar/calendar.component';
import { ListaAlumnosComponent } from './admin/alumnos/lista-alumnos/lista-alumnos.component';
import {CalendarService} from './alumno/calendar/calendar.service';
import { ListaPadresComponent } from './admin/padres/lista-padres/lista-padres.component';
import {PadreService} from 'app/admin/padres/padre.service';
import { FichaPadreComponent } from './admin/padres/ficha-padre/ficha-padre.component';
import { TablaAlumnosComponent } from './common/tabla-alumnos/tabla-alumnos.component';
import { FichaAlumnoComponent } from './admin/alumnos/ficha-alumno/ficha-alumno.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { HorariosComponent } from './admin/horarios/horarios.component';
import { FormPadreComponent } from './admin/padres/form-padre/form-padre.component';
import { FormAlumnoComponent } from './admin/alumnos/form-alumno/form-alumno.component';
import { ListaServiciosComponent } from './admin/servicios/lista-servicios/lista-servicios.component';
import { FichaServicioComponent } from './admin/servicios/ficha-servicio/ficha-servicio.component';
import { FormServicioComponent } from './admin/servicios/form-servicio/form-servicio.component';
import {AdminService} from './admin/admin.service';
import { ListaPartnersComponent } from './admin/partners/lista-partners/lista-partners.component';
import { FichaPartnerComponent } from './admin/partners/ficha-partner/ficha-partner.component';
import { FormPartnerComponent } from './admin/partners/form-partner/form-partner.component';
import { ModalHorarioEditComponent } from './admin/servicios/modal-horario-edit/modal-horario-edit.component';
import { NotFoundComponent } from './common/not-found/not-found.component';
import { PeriodosComponent } from './admin/facturas/periodos/periodos.component';
import { HomeFacturasComponent } from './admin/facturas/home-facturas/home-facturas.component';
import {AppMenuComponent, AppSubMenuComponent} from './header/app.menu.component';
import {AppTopBarComponent} from './header/app.topbar.component';
import {AppFooterComponent} from './header/app.footer.component';
import {AppBreadcrumbComponent} from './header/app.breadcrumb.component';
import {BreadcrumbService} from './header/breadcrumb.service';
import { ViewFacturaComponent } from './admin/facturas/view-factura/view-factura.component';
import {AdminGuard} from './auth/admin-guard.guard';
import {UserGuard} from './auth/user-guard.guard';
import {NoGuard} from './auth/no.guard';
import {MessageService} from 'primeng/components/common/messageservice';
import { SeriesComponent } from './admin/facturas/series/series.component';
import {ProgressBarModule} from 'primeng/progressbar';
// import { AppMenuComponent } from './common/menu/menu.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DropdownDirective,
    SigninComponent,
    UserComponent,
    DashboardComponent,
    RegisterComponent,
    AlumnoComponent,
    AlumnoEditComponent,
    AlumnoStartComponent,
    CalendarComponent,
    ListaAlumnosComponent,
    ListaPadresComponent,
    FichaPadreComponent,
    TablaAlumnosComponent,
    FichaAlumnoComponent,
    HorariosComponent,
    FormPadreComponent,
    FormAlumnoComponent,
    ListaServiciosComponent,
    FichaServicioComponent,
    FormServicioComponent,
    ListaPartnersComponent,
    FichaPartnerComponent,
    FormPartnerComponent,
    ModalHorarioEditComponent,
    NotFoundComponent,
    PeriodosComponent,
    HomeFacturasComponent,
    AppMenuComponent,
    AppTopBarComponent,
    AppFooterComponent,
    AppBreadcrumbComponent,
    AppSubMenuComponent,
    ViewFacturaComponent,
    SeriesComponent,
    //  AppMenuComponent,

  ],
  imports: [
    BrowserModule,
    AccordionModule,
    InputSwitchModule,
    AutoCompleteModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    AppRoutingModule,
    InputTextModule,
    DialogModule,
    ButtonModule,
    GrowlModule,
    DataTableModule,
    CheckboxModule,
    PasswordModule,
    MessagesModule,
    MenuModule,
    CalendarModule,
    DropdownModule,
    MultiSelectModule,
    ConfirmDialogModule,
    PanelModule,
    ChartModule,
    TabViewModule,
    NgbModule.forRoot(),
    ToastModule,
    ProgressBarModule
  ],
  providers: [
    DataStorageService,
    AuthService,
    AuthGuard,
    UserGuard,
    AdminGuard,
    NoGuard,
    UserService,
    AlumnoService,
    CalendarService,
    PadreService,
    AdminService,
    BreadcrumbService,
    MessageService,
    ConfirmationService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ModalHorarioEditComponent
  ]
})
export class AppModule {

}
