import { Component, OnInit } from '@angular/core';
import {AdminService} from '../../admin.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Periodo} from '../periodo.model';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-periodos',
  templateUrl: './periodos.component.html',
  styleUrls: ['./periodos.component.css']
})
export class PeriodosComponent implements OnInit {
  periodosFiltrados: Array<Periodo> = [];
  filtroDescripcion = '';
  alta = false;
  periodoEdit: Periodo;
  modal: any;
  displayDialog: boolean;


  constructor( private adminService: AdminService,
               private modalService: NgbModal) { }

  ngOnInit() {
    this.periodoEdit = new Periodo(-1, '', 2018, '', '');
    this.adminService.periodosUpdate.subscribe( periodo => this.onFilter());
    this.onFilter()
  }

  onFilter() {
    if (this.filtroDescripcion) {
      this.periodosFiltrados = this.adminService.periodos.filter(period =>
        (period.descripcion.toLowerCase().indexOf(this.filtroDescripcion.toLowerCase(), 0) !== -1 ));
    } else {
      this.periodosFiltrados = this.adminService.periodos
    }
  }

  onRowSelect(event) {
    const id = event.data.id;
    this.periodoEdit = new Periodo(this.periodosFiltrados[id].id,
      this.periodosFiltrados[id].descripcion,
      this.periodosFiltrados[id].ejercicio,
      this.periodosFiltrados[id].fechaInicio,
      this.periodosFiltrados[id].fechaFin);
    this.displayDialog = true;

  }

  guardarCambios() {
    this.alta = false;
    this.displayDialog = false;
    this.adminService.guardarObj(this.periodoEdit, 'config/periodos');
  }

  nuevoItem() {
    this.alta = true ;
    this.periodoEdit = new Periodo(this.adminService.periodos.length, '', 2018, '', '');
    this.displayDialog = true;
  }

}
