
export class Factura {
  id: number;
  numero: number;
  serie: string;
  numFactura: string  ;
  idUsuario: string;
  idLineaFactura: Array<number>;
  fechaFactura: string;
  status: boolean;
  tipoCobro: string;
  total: number;
  totalIVA: number;
  cobros: Array<number>;
  pendiente: number;
  comentarios: string;

  constructor(id: number, usuario: string, fecha: string = new Date().toISOString().substr(0 , 10) ) {
    this.id = id;
    this.idUsuario = usuario;
    this.fechaFactura = fecha;
    this.idLineaFactura = [];
    this.total = 0;
    this.totalIVA = 0;
    this.cobros = [];
  }
}
