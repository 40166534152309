import * as firebase from 'firebase';
import {Horario} from "./horario.model";

export class CalendarService  {

  horarios: Horario[] =[];
  horario: Array<Array<Horario>>;
  nivel: string;


  constructor() {

  }

  guardarHorario() {
    //firebase.database().ref('Horarios/Academia/HackerScout').set(this.horarios);
   // console.log('Array guardada')
  }

  cargarHorarios(niv) {
    this.horarios = [];
    if (niv !== "") {
      // console.log(nivel)
      this.nivel = niv;
      firebase.database().ref('Horarios/Academia/' + this.nivel).on('value',
        (response) => {
          const resultado = response.val();
          // console.log(this.horarios);
          for (const key in resultado) {
            if (!this.horarios[key]) {
              // Para restituir los horarios originales
              // firebase.database().ref('Horarios/Academia/'+this.nivel ).set(this.horarios);
            } else {
              this.horarios[key].alumnos = resultado[key].alumnos
            }
            if (!this.horarios[key].alumnos) {
              this.horarios[key].alumnos = [];
            }
          }
           // console.log(this.horarios);
        }
      );
    } else {
      alert('No hay Nivel!')
    }
  }

  updateHorario(aluKey: string, clase: string, add: boolean) {
    if (add) {
      this.horarios[clase].alumnos.push(aluKey);
    } else {
      this.horarios[clase].alumnos.splice(this.horarios[clase].alumnos.indexOf(aluKey), 1);
    }
    firebase.database().ref('Horarios/Academia/'+this.nivel+ '/' + clase +'/alumnos').set(this.horarios[clase].alumnos);
  }
}
