import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Servicio} from "../servicio.model";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-modal-horario-edit',
  templateUrl: './modal-horario-edit.component.html',
  styleUrls: ['./modal-horario-edit.component.css']
})
export class ModalHorarioEditComponent implements OnInit {

  @Input() servicio: Servicio;
  @Input() clase: number;
  activo: boolean;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.activo = this.servicio.horario[this.clase].activo
  }

  onConfirm(form: NgForm) {
    this.servicio.horario[this.clase].activo = this.activo
    this.servicio.horario[this.clase].hora = form.value.hora
    this.activeModal.close(this.servicio);
  }

}
