import { Component, OnInit } from '@angular/core';
import {AlumnoService} from '../../../alumno/alumno.service';
import {Router} from '@angular/router';
import {Alumno} from '../../../alumno/alumno.model';
import {BreadcrumbService} from '../../../header/breadcrumb.service';
import {AdminService} from '../../admin.service';
import {Inscripcion} from '../../../shared/inscripcion.model';
import {SelectItem} from 'primeng/primeng';
import {User} from '../../../auth/user/user.model';

@Component({
  selector: 'app-start-alumnos',
  templateUrl: './lista-alumnos.component.html',
  styleUrls: ['./lista-alumnos.component.css']
})
export class ListaAlumnosComponent implements OnInit {
  filtroApellidos = '';
  filtroNombre = '';
  alumnosFiltrados: Array <Alumno> = [];
  lugares: SelectItem[];

  constructor(private alumnoService: AlumnoService,
              private router: Router,
              private breadcrumbService: BreadcrumbService,
              private adminService: AdminService) {
    this.breadcrumbService.setItems([
      {label: 'Alumnos'}]);
    this.lugares = [];
    this.lugares.push( { label: 'Sin Servicio', value: -1 });
    for ( const pana of this.adminService.partners) {
      this.lugares.push( {label: pana.descripcion, value: pana.id});
    }

  }


  ngOnInit() {
    this.alumnoService.alumnosUpdate.subscribe( ( alus: Array<Alumno> ) => this.onFilter());
    this.onFilter();
  }

  onRowSelect(event) {
    const aluKey = this.alumnoService.alumnos.findIndex(alu => alu.key === event.data.key)
    this.router.navigate(['/fichaalumno', aluKey]);
  }

  onFilter() {
    if (this.filtroApellidos && this.filtroNombre) {
      this.alumnosFiltrados = this.alumnoService.alumnos.filter(alu =>
        (alu.nombre.toLowerCase().indexOf(this.filtroNombre.toLowerCase(), 0) !== -1) &&
        (alu.apellidos.toLowerCase().indexOf(this.filtroApellidos.toLowerCase(), 0) !== -1 ));
    } else if (this.filtroApellidos) {
      this.alumnosFiltrados = this.alumnoService.alumnos.filter(alu =>
        (alu.apellidos.toLowerCase().indexOf(this.filtroApellidos.toLowerCase(), 0) !== -1 ));
    } else if (this.filtroNombre) {
      this.alumnosFiltrados = this.alumnoService.alumnos.filter(alu =>
        (alu.nombre.toLowerCase().indexOf(this.filtroNombre.toLowerCase(), 0) !== -1 ));
    } else {
      this.alumnosFiltrados = this.alumnoService.alumnos
    }
   }

  mostrarLugares(aluKey: string) {
    const inscripciones = this.alumnoService.alumnos.find( alu => alu.key === aluKey).inscripciones;
    let respuesta = '';
    if (inscripciones) {
      for (const inscripcion of inscripciones) {
        const cole = this.adminService.partners[
          this.adminService.servicios[inscripcion.idServicio].idLugar].descripcion;
        if ( respuesta.indexOf(cole) === -1) {
          respuesta += cole + ' '
        }
      }
    }
    return respuesta;
  }

  onLugares(event, dt, col) {
    const values = [];
    if ( event.value.indexOf(-1) !== -1 ) {
      for (const al of this.alumnoService.alumnos.filter(alu => alu.inscripciones.length === 0 )) {
        values.push(al.key);
      }
    }
    for (const al of this.alumnoService.alumnos.filter(alu => alu.inscripciones.find(
      inscrip => event.value.indexOf( this.adminService.servicios[inscrip.idServicio].idLugar ) !== -1 ))) {
      values.push(al.key);
    }
    console.log(event.value.indexOf(-1))
    if ( values.length === 0 && ( event.value.length > 0 )) {
      values.push('');
    }
    dt.filter(values, col.field, col.filterMatchMode)
  }
}
