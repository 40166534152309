import {Component, Injectable, OnInit, Renderer, ElementRef, ViewChild} from '@angular/core';
import {AuthService} from './auth/auth.service';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

@Injectable()
export class AppComponent implements OnInit {
  menuClick: boolean;
  menuButtonClick: boolean;
  topbarMenuButtonClick: boolean;
  topbarMenuClick: boolean;
  topbarMenuActive: boolean;
  activeTopbarItem: Element;
  layoutStatic: boolean;
  sidebarActive: boolean;
  mobileMenuActive: boolean;
  darkMenu: boolean;
  verPrint: boolean;

  constructor(private router: Router,
              public authService: AuthService,
              public renderer: Renderer,
              private titleService: Title) {
    this.verPrint = true;
  }

  ngOnInit() {
    this.authService.start();
    if ( !this.authService.produccion ) {
      this.titleService.setTitle( 'IntraTest' );
    }
    this.router.navigate(['/']);
  }

  verMenus() {
    return this.authService.verPrint;
  }

  onWrapperClick() {
    if (!this.menuClick && !this.menuButtonClick) {
      this.mobileMenuActive = false;
    }

    if (!this.topbarMenuClick && !this.topbarMenuButtonClick) {
      this.topbarMenuActive = false;
      this.activeTopbarItem = null;
    }

    this.menuClick = false;
    this.menuButtonClick = false;
    this.topbarMenuClick = false;
    this.topbarMenuButtonClick = false;
  }

  onMenuButtonClick(event: Event) {
    this.menuButtonClick = true;

    if (this.isMobile()) {
      this.mobileMenuActive = !this.mobileMenuActive;
    }

    event.preventDefault();
  }

  onTopbarMobileMenuButtonClick(event: Event) {
    this.topbarMenuButtonClick = true;
    this.topbarMenuActive = !this.topbarMenuActive;
    event.preventDefault();
  }

  onTopbarRootItemClick(event: Event, item: Element) {
    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null; } else {
      this.activeTopbarItem = item; }

    event.preventDefault();
  }

  onTopbarMenuClick(event: Event) {
    this.topbarMenuClick = true;
  }

  onSidebarClick(event: Event) {
    this.menuClick = true;
  }

  onToggleMenuClick(event: Event) {
    this.layoutStatic = !this.layoutStatic;
  }

  isMobile() {
    return window.innerWidth < 1024;
  }

}
