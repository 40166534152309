import { Component, OnInit } from '@angular/core';
import {Partner} from '../partner.model';
import {AdminService} from '../../admin.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-lista-partners',
  templateUrl: './lista-partners.component.html',
  styleUrls: ['./lista-partners.component.css']
})
export class ListaPartnersComponent implements OnInit {

  partnersFiltrados: Array<Partner> = [];
  filtroDescripcion = '';


  constructor( private adminService: AdminService,
               private router: Router) { }

  ngOnInit() {
    this.onFilter()
    this.adminService.partnerUpdate.subscribe(
      ( partnerOrigen: Array<Partner>) => {
        this.onFilter(partnerOrigen);
        console.log(partnerOrigen);
      }
    );
  }

  onFilter(partners: Array<Partner> = []) {
    if (partners.length === 0) {
      partners = this.adminService.partners;
    }
   if (this.filtroDescripcion) {
      this.partnersFiltrados = partners.filter(pana =>
        (pana.descripcion.toLowerCase().indexOf(this.filtroDescripcion.toLowerCase(), 0) !== -1 ));
    } else {
      this.partnersFiltrados = partners;
    }
  }

  onRowSelect(event) {
    this.router.navigate(['/fichapartner', event.data.id]);

  }

}
