import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Params} from '@angular/router';
import {User} from '../../../auth/user/user.model';
import {PadreService} from '../padre.service';
import {NgForm} from '@angular/forms';
import {SelectItem, MenuItem, Message} from 'primeng/primeng';
import {MessageService} from 'primeng/components/common/messageservice';
import {UserService} from '../../../auth/user/user.service';

@Component({
  selector: 'app-form-padre',
  templateUrl: './form-padre.component.html',
  styleUrls: ['./form-padre.component.css']
})
export class FormPadreComponent implements OnInit {
  id: number;
  padre: User;
  permisos: SelectItem[];


  constructor(private inRouter: ActivatedRoute,
              private location: Location,
              public padreService: PadreService,
              private messageService: MessageService,
              private userService: UserService) { }

  ngOnInit() {
    this.permisos = [];
    let i = 0;
    while ( i < this.userService.datos.idPermiso ) {
      if ( this.padreService.permisos[i].length > 1 ) {
        this.permisos.push({label: this.padreService.permisos[i], value: i});
      }
      i++;
    }
    this.inRouter.params
      .subscribe(
        (params: Params) => {
          this.id = +params['id'];
          if (this.id < 0) {
            this.padre = new User( '', '', '', '');
            this.padreService.startAdmin();
          } else {
            this.padre = this.padreService.padres[this.id];
          }

        }
      );
  }

  goBack() {
    this.location.back();
  };

  formPadre(f: NgForm) {
    if (this.id < 0 ) {
         this.padreService.crearPadre(this.padre, f.value.password);
    } else {
      this.padreService.guardarPadre(this.padre);
    }
    this.goBack();
  }
}
