import {Horario} from '../../alumno/calendar/horario.model';


export class Servicio {
  public id: number;
  public descripcion: string;
  public ciclo: string;
  public nivel: string;
  public idNivel: number;
  public horario: Array<Horario>;
  public idLugar: number;
  public fechaInicio: string;
  public fechaFin: string;
  public precio: number;
  public capacidad: number;
  public puntual: boolean;
  public alumnos: Array<string>;



  constructor(id: number, descripcion: string, lugar: number, idNivel: number = -1) {
    this.id = id;
    this.descripcion =  descripcion;
    this.idLugar = lugar;
    this.capacidad = 8;
    this.horario = [];
    this.idNivel = idNivel;
    this.horario.push(new Horario(0, 'Lunes', '17:30 a 18:30'));
    this.horario.push(new Horario(1, 'Martes', '17:30 a 18:30'));
    this.horario.push(new Horario(2, 'Miercoles', '17:30 a 18:30'));
    this.horario.push(new Horario(3, 'Jueves', '17:30 a 18:30'));
    this.horario.push(new Horario(4, 'Viernes', '17:30 a 18:30'));
    this.horario.push(new Horario(5, 'Sabado', '10:00 a 11:00'));
    this.horario.push(new Horario(6, 'Lunes', '18:30 a 19:30'));
    this.horario.push(new Horario(7, 'Martes', '18:30 a 19:30'));
    this.horario.push(new Horario(8, 'Miercoles', '18:30 a 19:30'));
    this.horario.push(new Horario(9, 'Jueves', '18:30 a 19:30'));
    this.horario.push(new Horario(10, 'Viernes', '18:30 a 19:30'));
    this.horario.push(new Horario(11, 'Sabado', '11:00 a 12:00'));
    this.horario.push(new Horario(12, 'Sabado', '12:00 a 13:00'));
    this.puntual = false;
   }

}
