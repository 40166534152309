import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SigninComponent } from './auth/signin/signin.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {UserComponent} from './auth/user/user.component';
import {RegisterComponent} from './auth/register/register.component';
import {AlumnoComponent} from './alumno/alumno.component';
import {AlumnoEditComponent} from './alumno/alumno-edit/alumno-edit.component';
import {AlumnoStartComponent} from './alumno/alumno-start/alumno-start.component';
import {CalendarComponent} from './alumno/calendar/calendar.component';
import {ListaAlumnosComponent} from './admin/alumnos/lista-alumnos/lista-alumnos.component';
import {ListaPadresComponent} from './admin/padres/lista-padres/lista-padres.component';
import {FichaPadreComponent} from './admin/padres/ficha-padre/ficha-padre.component';
import {FichaAlumnoComponent} from './admin/alumnos/ficha-alumno/ficha-alumno.component';
import {HorariosComponent} from './admin/horarios/horarios.component';
import {FormPadreComponent} from './admin/padres/form-padre/form-padre.component';
import {FormAlumnoComponent} from './admin/alumnos/form-alumno/form-alumno.component';
import {ListaServiciosComponent} from './admin/servicios/lista-servicios/lista-servicios.component';
import {ListaPartnersComponent} from './admin/partners/lista-partners/lista-partners.component';
import {FormPartnerComponent} from './admin/partners/form-partner/form-partner.component';
import {FichaPartnerComponent} from './admin/partners/ficha-partner/ficha-partner.component';
import {FormServicioComponent} from './admin/servicios/form-servicio/form-servicio.component';
import {FichaServicioComponent} from './admin/servicios/ficha-servicio/ficha-servicio.component';
import {NotFoundComponent} from './common/not-found/not-found.component';
import {PeriodosComponent} from './admin/facturas/periodos/periodos.component';
import {HomeFacturasComponent} from './admin/facturas/home-facturas/home-facturas.component';
import {ViewFacturaComponent} from './admin/facturas/view-factura/view-factura.component';
import {AuthGuard} from './auth/auth-guard.service';
import {AdminGuard} from './auth/admin-guard.guard';
import {UserGuard} from './auth/user-guard.guard';
import {NoGuard} from './auth/no.guard';
import {SeriesComponent} from './admin/facturas/series/series.component';

const appRoutes: Routes = [
//  { path: '', redirectTo: '/recipes', pathMatch: 'full' },
  { path: '', component: DashboardComponent },
  { path: 'user', component: UserComponent, canActivate: [AuthGuard] },
  { path: 'calendar', component: CalendarComponent, canActivate: [UserGuard] },
  { path: 'register', component: RegisterComponent, canActivate: [NoGuard] },
  { path: 'facturas', component: HomeFacturasComponent , canActivate: [AdminGuard] },
  { path: 'verFactura/:id', component: ViewFacturaComponent, canActivate: [AdminGuard] },
  { path: 'periodos', component: PeriodosComponent, canActivate: [AdminGuard] },
  { path: 'series', component: SeriesComponent, canActivate: [AdminGuard] },
  { path: 'listapadres', component: ListaPadresComponent, canActivate: [AdminGuard] },
  { path: 'formpadre/:id', component: FormPadreComponent, canActivate: [AdminGuard] },
  { path: 'formalumno/:id/:padre', component: FormAlumnoComponent, canActivate: [AdminGuard] },
  { path: 'formalumno/:id', component: FormAlumnoComponent, canActivate: [AdminGuard] },
  { path: 'horarios', component: HorariosComponent, canActivate: [AdminGuard] },
  { path: 'fichapadre/:id', component: FichaPadreComponent, canActivate: [AdminGuard] },
  { path: 'fichaalumno/:id', component: FichaAlumnoComponent, canActivate: [AdminGuard] },
  { path: 'listaalumnos', component: ListaAlumnosComponent, canActivate: [AdminGuard] },
  { path: 'listaservicios', component: ListaServiciosComponent, canActivate: [AdminGuard] },
  { path: 'formservicio/:id', component: FormServicioComponent, canActivate: [AdminGuard] },
  { path: 'fichaservicio/:id', component: FichaServicioComponent, canActivate: [AdminGuard] },
  { path: 'fichaservicio/:id/:hora', component: FichaServicioComponent, canActivate: [AdminGuard] },
  { path: 'listapartners', component: ListaPartnersComponent, canActivate: [AdminGuard] },
  { path: 'formpartner/:id', component: FormPartnerComponent, canActivate: [AdminGuard] },
  { path: 'fichapartner/:id', component: FichaPartnerComponent, canActivate: [AdminGuard] },
  { path: 'signin', component: SigninComponent, canActivate: [NoGuard] },
  { path: 'alumno', component: AlumnoComponent, children: [
    { path: '', component: AlumnoStartComponent, canActivate: [UserGuard]  },
    { path: 'new', component: AlumnoEditComponent, canActivate: [UserGuard] },
    { path: ':id', component: AlumnoEditComponent, canActivate: [UserGuard] },
  //  { path: 'new', component: RecipeEditComponent, canActivate: [AuthGuard] },
  //  { path: ':id', component: RecipeDetailComponent },
//    { path: ':id/edit', component: RecipeEditComponent, canActivate: [AuthGuard] },
  ]  },
  { path: 'not-found', component: NotFoundComponent },
  { path: '**', redirectTo: '/not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
