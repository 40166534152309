import {Component, OnDestroy, OnInit} from '@angular/core';
import {DireccionFacturacion} from '../direccion-facturacion.model';
import {AdminService} from '../../admin.service';
import {ActivatedRoute, Params} from '@angular/router';
import {Factura} from '../factura.model';
import {LineaFactura} from '../lineafactura.model';
import {Location} from '@angular/common';
import {AlumnoService} from '../../../alumno/alumno.service';
import {UserService} from '../../../auth/user/user.service';
import {PadreService} from '../../padres/padre.service';
import {AuthService} from '../../../auth/auth.service';
import {AppComponent} from '../../../app.component';
import {forEach} from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-view-factura',
  templateUrl: './view-factura.component.html',
  styleUrls: ['./view-factura.component.css']
})
export class ViewFacturaComponent implements OnInit {
  emisor = new DireccionFacturacion();
  cliente: DireccionFacturacion;
  id: number;
  factura: Factura;
  lineas: Array<LineaFactura>;

  constructor(private adminService: AdminService,
              private inRouter: ActivatedRoute,
              private location: Location,
              private alumnoService: AlumnoService,
              private padreService: PadreService,
              public app: AppComponent) {
    this.app.verPrint = false;
    this.cliente = new DireccionFacturacion();
    this.factura = new Factura(-1, '');
    this.lineas = [];
    this.emisor.razonSocial = 'Tecnoaprendo SL';
    this.emisor.nif = 'B-57983686';
    this.emisor.direccion = 'Pare Cerda 16';
    this.emisor.poblacion = 'Palma de Mallorca';
    this.emisor.cp = '07014';


  }

  ngOnInit() {

    this.inRouter.params
      .subscribe(
        (params: Params) => {
          this.id = +params['id'];
          this.factura = this.adminService.facturas[this.id];
          console.log(this.factura)
          this.lineas = this.adminService.lineasFactura.filter(
            linea => this.factura.idLineaFactura.indexOf( linea.id ) !== -1 && linea.delete !== true );
          console.log(this.lineas)
        //  console.log(this.adminService.lineasFactura)
          this.recuperaDatos();
          this.factura.total = this.calculatotal();

          // this.calendarService.cargarHorarios(this.selectedNivel);
          // console.log(this.alumno);
        }
      );
  }

  cambio() {
    // this.authService.verPrint = !this.authService.verPrint;
  }

  calculatotal() {
    let total = 0
    for ( const linea of this.lineas) {
      total += linea.importe;
    }
    return total;
  }

  recuperaDatos() {
    const clienteUser = this.padreService.padres.find(papa => papa.key === this.factura.idUsuario );
    this.cliente.razonSocial = clienteUser.nombre + ' ' + clienteUser.apellidos;
    this.cliente.nif = clienteUser.dni;
    this.cliente.direccion = clienteUser.direccion;
    this.cliente.poblacion = clienteUser.poblacion;
    this.cliente.cp = clienteUser.cp;
  }

  goBack() {
    this.location.back();
  }

}
