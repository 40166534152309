import * as firebase from 'firebase';
import {Servicio} from './servicios/servicio.model';
import {Inscripcion} from '../shared/inscripcion.model';
import {Partner} from './partners/partner.model';
import {AlumnoService} from '../alumno/alumno.service';
import {PadreService} from './padres/padre.service';
import {Injectable} from '@angular/core';
import {Factura} from './facturas/factura.model';
import {User} from '../auth/user/user.model';
import {LineaFactura} from './facturas/lineafactura.model';
import {Periodo} from './facturas/periodo.model';
import {SelectItem} from 'primeng/primeng';
import {Subject} from 'rxjs/Subject';
import {Cobro} from './facturas/cobro.model';
import {Recibo} from './facturas/recibo.model';
import {Alumno} from '../alumno/alumno.model';
import {MessageService} from 'primeng/components/common/messageservice';
import {Serie} from './facturas/serie.model';

@Injectable()
export class AdminService {
  partnerUpdate = new Subject();
  partners: Array<Partner>;
  serviciosUpdate = new Subject();
  servicios: Array<Servicio>;
  periodosUpdate = new Subject();
  periodos: Array<Periodo>;
  seriesUpdate = new Subject();
  series: Array<Serie>;
  facturasUpdate = new Subject();
  facturas: Array<Factura>;
  lineasFactura: Array<LineaFactura>;
  preFacturas: Array<Factura>;
  preLineasFactura: Array<LineaFactura>;
  cobros: Array<Cobro>;
  recibos: Array<Recibo>;
  periodoActual: number ;
  serieActual: number ;
  serieAbono: number;
  facturasEnProceso = 0 ;
  facturasProgressUpdate = new Subject();
  tipoPartners: SelectItem[] = [{label: 'Elije Tipo', value: -1},
    {label: 'Academia', value: 0},
    {label: 'Colegio', value: 1},
    {label: 'Local', value: 2},
    {label: 'Evento', value: 3}];
  niveles: SelectItem [] = [{label: 'Seleccionar Nivel', value: -1},
    {label: 'MiniHacker', value: 0},
    {label: 'HackerJunior', value: 1},
    {label: 'HackerScout', value: 2}];
  fops: SelectItem[] = [{label: 'Efectivo', value: 0 },
    {label: 'Tarjeta', value: 1 },
    {label: 'Transferencia', value: 2 },
    {label: 'Domiciliación', value: 3 },
    {label: 'Abono', value: 4 }];
  primera = true;

  constructor(private padreService: PadreService,
              private alumnoService: AlumnoService,
              private messageService: MessageService) {

  }

  cargarDatos() {

    this.servicios = [];
    this.partners = [];
    this.periodos = [];
    this.series = [];
    this.facturas = [];
    this.lineasFactura = [];
    this.preFacturas = [];
    this.preLineasFactura = [];
    this.cobros = [];
    firebase.database().ref('servicios/').on('value',
      (response) => {
        const servis = response.val();
        // console.log(this.servicios)
        for (const idServicio in response.val()) {
          this.servicios[idServicio] = servis[idServicio]
          for (const idClase in this.servicios[idServicio].horario) {
            this.servicios[idServicio].horario[idClase].id = +idClase
            if (!this.servicios[idServicio].horario[idClase].alumnos) {
              this.servicios[idServicio].horario[idClase].alumnos = [];
            }
            // console.log(this.padres.find(padre => padre.key === key))
            if (!this.servicios[idServicio].alumnos) {
              this.servicios[idServicio].alumnos = [];
            }
          }
        }
        this.serviciosUpdate.next(this.servicios);

      });

    firebase.database().ref('partners/').on('value',
      (response) => {
        this.partners = response.val();
        this.partnerUpdate.next(this.partners)
        // console.log(this.partners)
      });
    /////////////////////
    // Cargo periodos //
    ////////////////////
    firebase.database().ref('config/periodos/').on('value',
      (response) => {
        this.periodos = response.val();
        this.periodosUpdate.next(this.periodos);
        this.periodoActual = this.periodos.findIndex(
          periodo =>
            (periodo.fechaFin >= this.hoy()) && (periodo.fechaInicio <= this.hoy()));
          while (this.periodoActual === -1 ) {
          const UltimoPeriodo = new Date(this.periodos[this.periodos.length - 1].fechaFin);
          const NuevoPeriodo = new Date(UltimoPeriodo.getFullYear(),UltimoPeriodo.getMonth() + 2, 0);
          const Period = +NuevoPeriodo.getFullYear();
          this.periodos.push(new Periodo(
            this.periodos.length,
            this.periodos[this.periodos.length - 12].descripcion.substring(0,
                  this.periodos[this.periodos.length - 12].descripcion.length - 2) + Period.toString().substr(2,4),
            Period,
            new Date(UltimoPeriodo.getFullYear(),UltimoPeriodo.getMonth() + 1,1).toISOString().substr(0 , 10),
            new Date(UltimoPeriodo.getFullYear(),UltimoPeriodo.getMonth() + 2,0).toISOString().substr(0 , 10)
            ));
            this.messageService.add({severity:'success', summary:'Nuevo Periodo Creado', detail:this.periodos[this.periodos.length - 1].descripcion.substring(0, this.periodos[this.periodos.length - 1].descripcion.length - 2) + Period.toString().substr(2,4)});
          this.periodoActual = this.periodos.findIndex(
            periodo =>
              (periodo.fechaFin >= this.hoy()) && (periodo.fechaInicio <= this.hoy()));
            this.guardarObj(this.periodos[this.periodos.length - 1], 'config/periodos')
        }
        // console.log(this.partners)
      });
    //////////////////
    // Cargo Series //
    //////////////////
    firebase.database().ref('config/series/').on('value',
      (response) => {
        this.series = response.val();
      //  console.log(this.series)
        this.seriesUpdate.next(this.series);
        this.serieActual = this.series.findIndex( seri =>
           (this.periodos[this.periodoActual].ejercicio === seri.ejercicio) && (seri.tipo === 'F') ) ;
        this.serieAbono = this.series.findIndex( seri =>
          (this.periodos[this.periodoActual].ejercicio === seri.ejercicio) && (seri.tipo === 'A') ) ;
        while (this.serieActual === -1 ) {
          this.series.push(new Serie(this.series.length, this.periodos[this.periodoActual].ejercicio, 'F'));
          this.messageService.add({severity: 'success', summary: 'Nueva Serie Creada', detail: "Ejercicio: " + this.periodos[this.periodoActual].ejercicio + " Tipo: F contador a 0" });
          this.serieActual = this.series.findIndex(
            serie =>
              (this.periodos[this.periodoActual].ejercicio === serie.ejercicio) && (serie.tipo === 'F'));
          this.guardarObj(this.series[this.series.length - 1], 'config/series')
        }
      });
    //////////////////////////
    // Cargo Lineas Factura //
    //////////////////////////
    firebase.database().ref('facturacion/lineasFactura/').on('value',
      (response) => {
        if (response.val()) {
          this.lineasFactura = response.val();
          this.facturasUpdate.next(this.facturas);
        }
      });
    //////////////////
    // Cargo cobros //
    //////////////////
    firebase.database().ref('facturacion/cobros/').on('value',
      (response) => {
        if (response.val()) {
          this.cobros = response.val();
          this.facturasUpdate.next(this.facturas);
        }
      });
    ////////////////////
    // Cargo Facturas //
    ////////////////////
    firebase.database().ref('facturacion/facturas/').on('value',
      (response) => {
        const facturas = response.val();
        // console.log(this.servicios)
        for (const idFactura in response.val()) {
          this.facturas[idFactura] = facturas[idFactura];
          if (!this.facturas[idFactura].cobros) {
            this.facturas[idFactura].cobros = [];
            //  } else {
            //    this.facturas[idFactura].cobros = [];
            //    this.guardarObj(this.facturas[idFactura], 'facturacion/facturas');
          }
          // console.log(this.padres.find(padre => padre.key === key))
          if (!this.facturas[idFactura].idLineaFactura) {
            this.facturas[idFactura].idLineaFactura = [];
          }
        }
        this.facturasUpdate.next(this.facturas);
        //   for ( let id = 1956 ; id <= 2002 ; id++) {
        //     this.borrarObj(id, 'facturacion/facturas');
        //   }
        // 3120 a 3125 3378
        // 1956 a 1961
      });
  }

  hoy() {
    return new Date().toISOString().substr(0 , 10);
  }

  borrarObj(id: number, tipo: string) {
    firebase.database().ref(tipo + '/' + id).remove(
      a => {
        if ( !a) {
          console.log( tipo + ' con id: ' + id + ' ha sido eliminado correctamente');
        }
      });
  }

  guardarObj(datos: any, tipo: string) {
    // console.log(datos)
    //  console.log('saveUser> Guardando datos de Usuario:' + this.datos);
    firebase.database().ref(tipo + '/' + datos.id).set(datos);
    //  console.log('saveUser> Guardado: ' + this.datos);
  }

  updateHorario(aluKey: string, inscripcion: Inscripcion, add: boolean) {
    // console.log(inscripcion)
    let alumnos: Array<string>
    let ruta = '';
    if (this.servicios[inscripcion.idServicio].puntual) {
      alumnos = this.servicios[inscripcion.idServicio].alumnos;
      ruta = 'servicios/' + inscripcion.idServicio + '/alumnos';
    } else {
      alumnos = this.servicios[inscripcion.idServicio].horario[inscripcion.idHorario].alumnos;
      ruta = 'servicios/' + inscripcion.idServicio + '/horario/' + inscripcion.idHorario + '/alumnos';
    }
    // console.log(alumnos)
    if (add) {
      alumnos.push(aluKey);
    } else {
      alumnos.splice(alumnos.indexOf(aluKey), 1);
    }
    firebase.database().ref(ruta).set(alumnos);
  }

  // ******************************
  // ******Modulo de Facturas******
  // ******************************

  preparaFacturas(idPeriodo: number) {
    this.preFacturas = [];
    this.preLineasFactura = [];
    if (this.padreService.padres) {
      for (const padre of this.padreService.padres.filter(dad => dad.idPermiso === 1)) {
        if (this.partners.find(pana => pana.idUser === padre.key).facturacion) {
          // por le momento pongo factura lenght pero habra que cambiarlo con la longitud del array real
          const newFact = this.creaPreFactura(this.preFacturas.length, padre, idPeriodo);
          if (newFact.idLineaFactura.length > 0) {
            this.preFacturas.push(newFact);
          }
        }
      }
      for (const padre of this.padreService.padres.filter(dad => dad.idPermiso !== 1)) {
        if (padre.hijos.length > 0 ) {
          // por le momento pongo factura lenght pero habra que cambiarlo con la longitud del array real
          const newFact = this.creaPreFactura(this.preFacturas.length, padre, idPeriodo);
          if (newFact.idLineaFactura.length > 0) {
            this.preFacturas.push(newFact);
          }
        }
      }
    }
    // console.log(this.preFacturas)
    return this.preFacturas;
  }

  creaPreFactura(id, padre: User, idPeriodo: number) {
    const factura = new Factura(id, padre.key, this.fechaFactura(this.periodos[idPeriodo].fechaFin));
    let hijos: Array<Alumno>;
    const serviciosColes = [];
    if (padre.idPermiso === 1) { // Es un cole
      // Recupero los servicios de los coles de facturacion y sus alumnos inscritos
      for (const serv of this.servicios.filter(
          servi => servi.idLugar === this.partners.find(pana => pana.idUser === padre.key ).id)) {
          serviciosColes.push(serv.id);
        }
        hijos = this.alumnoService.alumnos.filter( alumno => alumno.inscripciones.find(
        inscrip => serviciosColes.indexOf(inscrip.idServicio) !== -1));
        factura.serie = this.series[this.serieActual].tipo;
    } else {
      // Si es padre, le facturo los servicios de su hijo
      hijos = this.alumnoService.alumnos.filter(alu => padre.hijos.indexOf(alu.key) !== -1);
      factura.serie = 'F';
      // factura.serie = 'D'; // esto debe ser descomentado para separar las directas de las facturas a colegios
    }
    let i = 0;
    // Facturo to do lo de este periodo y 2 meses mas atras
    while (i < 3 && idPeriodo >= 0) {
      for (const hijo of hijos) {
        if (hijo.inscripciones.length > 0) {
          // Si tiene Inscripciones, las reviso para ver si estan facturadas y si son del mes entero (modificador)
          for (const inscripcion of hijo.inscripciones) {
            // Con este IF solo facturo los servicios del cole a los coles y dejo pendientes los de la academias para el padre
            if (serviciosColes.length === 0 || serviciosColes.indexOf(inscripcion.idServicio) !== -1) {
              const modificador = this.getModificador(inscripcion, this.periodos[idPeriodo]);
              // Chequeo que el servicio, Horario, Periodo del alumno, no este facturado
              // Tambien chequeo que el modificador (dias dentro del mes sean mayor que 0, es decir, al menos ha ido a 1 clase
              if (modificador > 0 && !this.lineasFactura.find(
                linea => this.checkBilled(linea, inscripcion, hijo.key, idPeriodo))
                && !this.preLineasFactura.find(
                  linea => this.checkBilled(linea, inscripcion, hijo.key, idPeriodo))) {
                // Preparo linea factura
                const idLinea = this.preLineasFactura.length;
                factura.idLineaFactura.push(idLinea);
                this.preLineasFactura.push(new LineaFactura(idLinea,
                  id,
                  hijo.key,
                  inscripcion.idServicio,
                  inscripcion.idHorario,
                  idPeriodo,
                  inscripcion.precio * modificador,
                  inscripcion.descuento,
                  this.getDescripcion(inscripcion, idPeriodo) + ((modificador < 1) ? ' (' + (modificador * 4) + ' dias)' : '')
                ));
                factura.total += this.preLineasFactura[idLinea].total;
              }
            }
          }
        }
      }
      idPeriodo--;
      i++
    }
    // console.log(factura);
    return factura;
  }

  fechaFactura(fecha: string = new Date().toISOString().substr(0 , 10)) {
    if ( fecha > new Date().toISOString().substr(0 , 10)) {
      fecha = new Date().toISOString().substr(0 , 10);
    }
    if ( fecha < this.facturas[this.facturas.length - 1].fechaFactura ) {
      fecha = this.facturas[this.facturas.length - 1].fechaFactura;
    }
    return fecha;  }

  checkBilled(linea: LineaFactura, inscripcion: Inscripcion, aluKey: string, idPeriodo: number) {
    return (linea.idServicio === inscripcion.idServicio &&
      linea.idHorario === inscripcion.idHorario &&
      linea.idAlumno === aluKey &&
      linea.idPeriodo === idPeriodo);
  }

  getDescripcion(inscripcion: Inscripcion, periodo: number) {
    const serv = this.servicios[inscripcion.idServicio];
    let descripcion = serv.descripcion;
    if (!serv.puntual) {
      descripcion += ' los ' + serv.horario[inscripcion.idHorario].dia;
      // descripcion += ' de ' + serv.horario[inscripcion.idHorario].hora;
      descripcion += ' de ' + this.periodos[periodo].descripcion;
    }
    return descripcion;
  }

  getModificador(inscripcion: Inscripcion, periodo: Periodo) {
    let modificador = 0;
    if ((this.servicios[inscripcion.idServicio].puntual === true && ( inscripcion.fechaInicio >= periodo.fechaInicio && inscripcion.fechaInicio <= periodo.fechaFin)) ||
      ( inscripcion.fechaInicio <= periodo.fechaInicio && inscripcion.fechaFin >= periodo.fechaFin) ||
      ( (((new Date(this.servicios[inscripcion.idServicio].fechaFin).getTime()
        - new Date(this.servicios[inscripcion.idServicio].fechaInicio).getTime()) / ( 1000 * 3600 * 24  )) < 29)) &&
      ( inscripcion.fechaInicio >= periodo.fechaInicio && inscripcion.fechaInicio <= periodo.fechaFin)) {
      modificador = 1
    } else if (( inscripcion.fechaInicio > periodo.fechaInicio && inscripcion.fechaInicio < periodo.fechaFin)) {
      modificador = Math.round(+(new Date(periodo.fechaFin).getTime()
        - new Date(inscripcion.fechaInicio).getTime())
        / ( 1000 * 3600 * 24 * 7 )) / 4;
    } else if (( inscripcion.fechaFin > periodo.fechaInicio && inscripcion.fechaFin < periodo.fechaFin)) {
      modificador = Math.round((new Date(inscripcion.fechaFin).getTime()
        - new Date(periodo.fechaInicio).getTime()) / ( 1000 * 3600 * 24 * 7 )) / 4;
    }
    return modificador;
  }

  creaFacturaFinal(factura: Factura, lineas: Array<LineaFactura>, fechaFactura: string = '', abono: boolean = false) {
    const idPre = this.preFacturas.findIndex(fact => fact.id === factura.id);
    const id = this.facturas.length;
    // Configuro la cabecera de la factura final
    this.facturas.push(factura);
    this.facturas[id].id = id;
    const serieFactura = ( abono ) ? this.serieAbono : this.serieActual;
    this.series[serieFactura].contador++;
    this.facturas[id].numero = this.series[serieFactura].contador ;
    this.facturas[id].serie +=  this.series[serieFactura].ejercicio.toString().substr(2, 2);
    if (fechaFactura !== '') {
      this.facturas[id].fechaFactura = fechaFactura;
    }
    let s = this.facturas[id].numero.toString();
    while (s.length < 5 ) {
      s = '0' +  s
    }
    this.facturas[id].numFactura = this.facturas[id].serie + s;
    ////////////////////////////////////
    // traspaso las lineas de factura //
    ////////////////////////////////////
    this.facturas[id].idLineaFactura = [];
    for (const linea of lineas) {
      const idLinea = this.lineasFactura.length;
      this.lineasFactura.push(linea);
      this.lineasFactura[idLinea].idFactura = id;
      this.lineasFactura[idLinea].id = idLinea;
      this.facturas[id].idLineaFactura.push(idLinea);
      console.log('linea factura en creacion ' + this.lineasFactura[idLinea].importe)
      this.guardarObj(this.lineasFactura[idLinea], 'facturacion/lineasFactura');
    }
    if (abono) {
      const idcobro = this.cobros.length ;
      this.cobros.push(new Cobro( idcobro, id , factura.total, 4, fechaFactura));
      this.guardarObj(this.cobros[idcobro], 'facturacion/cobros');
      this.facturas[id].cobros.push(idcobro);
    } else {
      this.preLineasFactura.splice(this.preLineasFactura.findIndex(linea => linea.id === lineas[0].id), lineas.length);
      this.preFacturas.splice(idPre, 1) ;
    }
    // Guardo factura final y limpio las prefacturas de lo que ya se ha facturado
    this.guardarObj(this.facturas[id], 'facturacion/facturas');
    this.guardarObj(this.series[serieFactura], 'config/series');
  }

  facturarTodo(preFact: Array<Factura> , fechaFactura: string = '') {
    this.facturasEnProceso = 0 ;
    for (const factura of preFact ) {
       this.creaFacturaFinal(factura, this.preLineasFactura.filter(
         linea => (factura.idLineaFactura.indexOf(linea.id) !== -1)), fechaFactura);
      this.facturasEnProceso++ ;
      this.facturasProgressUpdate.next(this.facturasEnProceso);
    }
    this.facturasUpdate.next( this.facturas )
    this.preparaFacturas(this.periodoActual);
  }

  Cobrado(factura: any) {
  // console.log(factura)
    const cobrosObj = this.cobros.filter(cob => factura.cobros.indexOf(cob.id) !== -1);
    let cobros = 0;
    for (const cobro of cobrosObj) {
      cobros += cobro.importe;
    }
    return factura.total - cobros;
  }

  prepararRecibos(idPeriodo: number) {
    this.recibos = [];
    if (this.facturas) {
      const facturas = this.facturas.filter(
        factura => factura.fechaFactura >= this.periodos[idPeriodo].fechaInicio &&
          this.periodos[idPeriodo].fechaFin >= factura.fechaFactura && this.Cobrado(factura) > 0 &&
          this.padreService.padres.find(padre => padre.key === factura.idUsuario).cuenta);
      for ( const factura of facturas ) {
        const recibo = new Recibo();
        recibo.referencia = 'Tecnoaprendo ' + factura.numFactura;
        recibo.descripcion = 'Clases de ' + this.periodos[idPeriodo].descripcion;
        const padre = this.padreService.padres.find( dad => dad.key === factura.idUsuario);
        recibo.titular = ( padre.titular ) ? padre.titular : padre.nombre + ' ' + padre.apellidos;
        recibo.total = factura.total ;
        recibo.iban = padre.cuenta;
        recibo.id = this.recibos.length;
        recibo.idFactura = factura.id ;
        this.recibos.push(recibo);
      }
    }
    return this.recibos;
  }

  nombreAlumno(idAlu: string) {
    const alumno = this.alumnoService.alumnos.find(alu => alu.key === idAlu);
    return alumno.nombre + ' ' + alumno.apellidos;
  }
}


