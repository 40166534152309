import * as firebase from 'firebase';
import {User} from './user.model';
import {NgForm} from '@angular/forms';
import {Alumno} from '../../alumno/alumno.model';
import {Injectable} from '@angular/core';
import {AlumnoService} from '../../alumno/alumno.service';
import {PadreService} from '../../admin/padres/padre.service';
import {AdminService} from '../../admin/admin.service';
import {Subject} from 'rxjs/Subject';

@Injectable()
export class UserService {
  datosUpdate = new Subject();
  datos: User;
  uid: string;
  hijos: Array<Alumno> = [];
  admin= false;
  cargado = false;
  cargaUpdate = new Subject();

  constructor( private alumnoService: AlumnoService,
               private padreService: PadreService,
               private adminService: AdminService) {
    this.alumnoService.alumnosUpdate.subscribe( alu => this.checkCarga() );
    this.padreService.padresUpdate.subscribe( dad => this.checkCarga());
    this.adminService.partnerUpdate.subscribe( panas => this.checkCarga());
    this.adminService.serviciosUpdate.subscribe( servis => this.checkCarga());
    this.adminService.periodosUpdate.subscribe( periodo => this.checkCarga());
    this.adminService.facturasUpdate.subscribe( periodo => this.checkCarga());

  }

  checkCarga() {
    if (!this.cargado ) {
      if (this.alumnoService.alumnos.length > 0  && this.padreService.padres.length > 0 && this.adminService.servicios.length > 0 &&
        this.adminService.partners.length > 0 && this.adminService.periodos.length > 0 && this.adminService.facturas.length > 0) {
        this.cargado = true;
        this.cargaUpdate.next(this.cargado);
      }
    }
  }

  getUserData() {
    return this.datos;
  }

  updateUser(nombre: string, apellidos: string, telefono: string, marketing: boolean) {
    this.datos.nombre = nombre;
    this.datos.apellidos = apellidos;
    this.datos.telefono = telefono;
    this.datos.marketing = marketing;
    this.saveUser();
   // console.log('actualizo');
  }

  newUser(uid: string, f: NgForm) {
     this.datos = new User(f.value.nombre, f.value.apellidos, f.value.telefono, f.value.email, f.value.marketing);
   //  console.log('newUser> Creo nuevo usuario' + this.datos);
      this.datos.key = uid;
      this.datos.fechaAlta = new Date().toLocaleDateString();
      this.uid = uid;
      this.saveUser();
  }

  saveUser(user: User = this.datos) {
  //  console.log('saveUser> Gaurdando datos de Usuario:' + this.datos);
    firebase.database().ref('users/' + user.key).set(user);
  //  console.log('saveUser> Guardado: ' + this.datos);
  }

  loadUser(uid: string) {
    firebase.database().ref('users/' + uid).on('value',
      (response) => {
        this.datos = response.val();
        if (!this.datos.hijos) {
          this.datos.hijos = [];
        }
        if ( response.val().admin ) {
          this.admin = response.val().admin;
          this.alumnoService.loadAlumnos();
          this.padreService.loadPadres();
          this.adminService.cargarDatos();
        }

        if ( (this.admin) && !this.datos.idPermiso) {
          this.datos.idPermiso = 9;
        } else if (!this.datos.idPermiso) {
          this.datos.idPermiso = 0;
        }


        if (!this.datos.hijos) {
          this.datos.hijos = [];
        } else {
         // console.log(this.datos.hijos);
          this.loadAlumnos(this.datos.hijos);
        }
       // console.log(response.val())
        this.datosUpdate.next(this.datos);
        // console.log('loadUser> Recibo datos: ' + this.datos);
      }
      );
    this.uid = uid;
  }

  // Seccion para hijos o alumnos

  newAlumno(hijo: Alumno, uid: string = this.uid) {
    const id = firebase.database().ref('alumnos/').push(hijo);
    hijo.key = id.key;
    this.guardaHijo(hijo);
    return id.key;
    // this.datos.hijos.push(id.key);
    // this.saveUser(uid);
  //  console.log('newAlumno> asi quedo despues de guardar  :' + this.datos);
  }

  loadAlumnos(hijos: Array<string>) {
    this.hijos = [];
    for (const hijo of hijos) {
      firebase.database().ref('alumnos/' + hijo).on('value',
        (response) => {
         // console.log('cargo hijo: ' + this.hijos[response.val().id]);
          if (!this.hijos[response.val().id] ) {
            this.hijos.push(new Alumno(
              response.val().id,
              response.val().nombre,
              response.val().apellidos,
              response.val().birthday,
              response.val().rrss,
              response.val().padre,
              response.val().nivel));
          }
        }
      );


    }
  }
  guardaHijo( hijo ) {
    firebase.database().ref('alumnos/' + hijo.key).set(hijo);
  //  console.log('guardo hijo ' + id + ' con ID ' + hijo.id)
  }


}
