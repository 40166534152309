import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {Router} from '@angular/router';
import {UserService} from '../auth/user/user.service';
import {AdminService} from '../admin/admin.service';
import {AlumnoService} from '../alumno/alumno.service';
import {PadreService} from '../admin/padres/padre.service';
import {Alumno} from '../alumno/alumno.model';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [MessageService]
})
export class DashboardComponent implements OnInit {
  public nuevosRegistros: Array<any>;
  barData: any;

  constructor(private router: Router,
              private authService: AuthService,
              private userService: UserService,
              private adminService: AdminService,
              private alumnoService: AlumnoService,
              private padreService: PadreService,
              private messageService: MessageService) { }

  ngOnInit() {
    this.nuevosRegistros = [];
    if (this.authService.isLoaded() ) {
      this.adminService.preparaFacturas(this.adminService.periodoActual);
       this.cargaNuevosRegistros();
    }
    if (this.userService.datos) {
      if (this.authService.isUserAuthenticated()) {
        this.router.navigate(['/alumno']);
      }
    } else {
       this.router.navigate(['/signin']);
    }

  }

  cargaNuevosRegistros() {
    this.nuevosRegistros = [];
    for ( const alu of this.alumnoService.alumnos.filter(alumno => alumno.inscripciones.find(
      inscrip => ((new Date().getTime() - new Date(inscrip.fechaInicio).getTime()) / (24 * 3600000)) < 15 ))) {
      const inscripcion = alu.inscripciones.find(inscrip => ((new Date().getTime() -
        new Date(inscrip.fechaInicio).getTime()) / (24 * 3600000)) < 15);
      const profe = this.padreService.padres.find(dad => dad.key ===
        this.adminService.servicios[inscripcion.idServicio].horario[inscripcion.idHorario].idProfe);
      this.nuevosRegistros.push({
        nombre: alu.nombre + ' ' + alu.apellidos,
        servicio: this.adminService.servicios[inscripcion.idServicio].descripcion,
        inicio: inscripcion.fechaInicio,
        horario: this.adminService.servicios[inscripcion.idServicio].horario[inscripcion.idHorario].dia + ' ' +
        this.adminService.servicios[inscripcion.idServicio].horario[inscripcion.idHorario].hora,
        profe: ( profe ) ? profe.nombre + ' ' + profe.apellidos : ''
      });
    }
    this.barData = {
      labels: [],
      datasets: [
        {
          label: 'Periodos',
          backgroundColor: '#03A9F4',
          borderColor: '#03A9F4',
          data: []
        },
      ]
    };
    for ( const periodo of this.adminService.periodos ) {
      if (periodo.id <= this.adminService.periodoActual && periodo.id > this.adminService.periodoActual - 13 ) {
        let sum = 0;
        for (const factura of this.adminService.facturas.filter(
          fact => fact.fechaFactura >= periodo.fechaInicio && fact.fechaFactura <= periodo.fechaFin)) {
          sum += factura.total;
        }
        if (sum > 0) {
          this.barData.labels.push(periodo.descripcion);
          this.barData.datasets[0].data.push(sum);
        } else {
          this.barData.labels.push(periodo.descripcion);
          this.barData.datasets[0].data.push(sum);
          //break;
        }
      }
    }
    // console.log(this.nuevosRegistros)
  }

  redirect() {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['/signin']);
    } else if (this.authService.isUserAuthenticated()) {
      this.router.navigate(['/alumno']);
    }
  }

}
