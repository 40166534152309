import { Component, OnInit } from '@angular/core';
import {CalendarService} from '../../alumno/calendar/calendar.service';
import {Alumno} from '../../alumno/alumno.model';
import {AlumnoService} from '../../alumno/alumno.service';
import {Router} from '@angular/router';
import {Servicio} from '../servicios/servicio.model';
import {AdminService} from '../admin.service';
import {forEach} from '@angular/router/src/utils/collection';
import {Horario} from '../../alumno/calendar/horario.model';
import {PadreService} from '../padres/padre.service';
import {UserService} from '../../auth/user/user.service';

@Component({
  selector: 'app-horarios',
  templateUrl: './horarios.component.html',
  styleUrls: ['./horarios.component.css']
})
export class HorariosComponent implements OnInit {
  Niveles: Array<string> = ['MiniHacker', 'HackerJunior', 'HackerScout']
  dia: number;
  ListaClases: Array<Servicio> = [];
  ListaAlumnos: Array<Array<Alumno>>;
  horario: Array<Horario>;


  constructor(private calendarService: CalendarService,
              public alumnoService: AlumnoService,
              private router: Router,
              private adminService: AdminService,
              private padreService: PadreService,
              private userService: UserService) { }

  ngOnInit() {
    this.ListaClases = [];
    this.ListaAlumnos = [];
    this.dia = 0;
    this.horario = [];
    this.horario.push(new Horario(0, 'Lunes', 'Primera Hora', true));
    this.horario.push(new Horario(1, 'Martes', 'Primera Hora', true));
    this.horario.push(new Horario(2, 'Miercoles', 'Primera Hora', true));
    this.horario.push(new Horario(3, 'Jueves', 'Primera Hora', true));
    this.horario.push(new Horario(4, 'Viernes', 'Primera Hora', true));
    this.horario.push(new Horario(5, 'Sabado', 'Primera Hora', true));
    this.horario.push(new Horario(6, 'Lunes', 'Segunda Hora', true));
    this.horario.push(new Horario(7, 'Martes', 'Segunda Hora', true));
    this.horario.push(new Horario(8, 'Miercoles', 'Segunda Hora', true));
    this.horario.push(new Horario(9, 'Jueves', 'Segunda Hora', true));
    this.horario.push(new Horario(10, 'Viernes', 'Segunda Hora', true));
    this.horario.push(new Horario(11, 'Sabado', 'Segunda Hora', true));
    this.horario.push(new Horario(12, 'Sabado', 'Tercera Hora', true));


  }

  onDaySelect(classData: {ClassId: number }) {
    this.ListaClases = [];
    this.ListaAlumnos = [];
    this.dia = classData.ClassId;
    // console.log(classData.ClassId)
    for (const servicio of this.adminService.servicios) {
      if (servicio.horario[this.dia].activo && ( servicio.horario[this.dia].alumnos.length > 0
          || (servicio.alumnos.length > 0 && servicio.puntual) ) ) {
        this.ListaClases.push(servicio);
        if (servicio.puntual) {
          this.ListaAlumnos.push(
            this.alumnoService.alumnos.filter( alumno =>
              (servicio.alumnos.indexOf(alumno.key) !== -1) &&
              this.alumnoService.activoClase( alumno.key, this.dia, servicio.id, servicio.puntual)));
        } else {
          this.ListaAlumnos.push(
            this.alumnoService.alumnos.filter( alumno =>
              (servicio.horario[this.dia].alumnos.indexOf(alumno.key) !== -1) &&
              this.alumnoService.activoClase( alumno.key, this.dia, servicio.id, servicio.puntual)));
        }

      }
    }

   // const clase = this.calendarService.horarios[classData.ClassId];
    // this.ListaAlumnos = this.alumnoService.alumnos.filter( alumno => (clase.alumnos.indexOf(alumno.key) !== -1));
    // console.log(this.ListaClases)
  }

  recuperaProfe(idProfe: string) {
    if (idProfe) {
      const profe = this.padreService.padres.find(user => user.key === idProfe) ;
      return profe.nombre + ' ' + profe.apellidos;
    } else {
      return 'No Asignado';
    }
  }

  onKidSelect(a: any) {
    this.router.navigate(['/fichaalumno', a.KidId]);
  }

}
