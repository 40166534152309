
import {Inscripcion} from '../shared/inscripcion.model';

export class Alumno {
  public id: number;
  public nombre: string;
  public apellidos: string;
  public birthtime: number;
  public birthday: string;
  public rrss: boolean;
  public padre: string;
  public idNivel: number;
  public nivel: string;
  public inscripciones: Array<Inscripcion>;
  public key: string;
  public comments: string ;
  public idColegio: number;
  public otroColegio: string;

  constructor(id: number,
              nombre: string,
              apellidos: string,
              birthday: string,
              rrss: boolean,
              padre: string,
              nivel: string = '',
              inscripcion: Array<Inscripcion> = [],
              comments: string = '') {
    this.id = id;
    this.nombre = nombre;
    this.apellidos = apellidos;
    this.birthday = birthday;
    this.rrss = rrss;
    this.padre = padre;
    this.nivel = nivel;
    this.inscripciones = inscripcion;
    this.key = '' ;
    this.comments = comments;
    this.idColegio = -1;
  }

}

