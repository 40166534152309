export class Inscripcion {
  public id: number;
  public idServicio: number;
  public idHorario: number;
  public idNivel: number;
  public fechaInicio: string;
  public fechaFin: string;
  public precio: number;
  public descuento: number;
  public nivel: string;
  public total: number;

  constructor(idNivel: number,
              idServicio: number,
              idHorario: number,
              precio: number = 40,
              fin: string = '2018-06-22',
              inicio: string = new Date().toISOString().substr(0 , 10),
              descuento: number = 0) {
    this.idNivel = idNivel;
    this.idHorario = idHorario;
    this.idServicio = idServicio;
    this.fechaInicio = inicio;
    this.fechaFin = fin;
    this.precio = precio;
    this.descuento = descuento;
    this.total = precio - ( precio * descuento);
  }

}
