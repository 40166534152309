import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdminService} from '../../admin.service';
import {PadreService} from '../../padres/padre.service';
import {AlumnoService} from '../../../alumno/alumno.service';
import {Router} from '@angular/router';
import {SelectItem} from 'primeng/primeng';
import {Recibo} from '../recibo.model';
import {Cobro} from '../cobro.model';
import {LineaFactura} from '../lineafactura.model';
import {Factura} from '../factura.model';
import {AuthService} from '../../../auth/auth.service';
import {AppComponent} from '../../../app.component';
import {UserService} from '../../../auth/user/user.service';

@Component({
  selector: 'app-home-facturas',
  templateUrl: './home-facturas.component.html',
  styleUrls: ['./home-facturas.component.css']
})
export class HomeFacturasComponent implements OnInit {
  periodoSelected: number;
  periodos: SelectItem[];
  recibos: Array<Recibo>;
  facturas: Array<any>;
  cobros: Array<any>;
  preFacturas: Factura[];
  preFacturasSelected: Factura[];
  lineasFiltradas: Array<LineaFactura>;
  displayDialog: boolean;
  ordenFactura = 0;
  totalFacturas = 0;
  fechaFactura: string = new Date().toISOString().substr(0 , 10);

  constructor(public adminService: AdminService,
              public padreService: PadreService,
              public alumnoService: AlumnoService,
              private router: Router,
              public userService: UserService,
              public app: AppComponent) {
    if ( !this.app.verPrint ) {
      // this.app.verPrint = true;
    }
    this.recibos = [];
  }

  ngOnInit() {
    this.adminService.periodosUpdate.subscribe( period => this.cargarPeriodos());
    this.adminService.facturasUpdate.subscribe( fact => this.cargarFacturas() );
    this.periodoSelected = this.adminService.periodoActual;
    this.cargarFacturas();
    this.cargarPeriodos();
    this.cargarCobros();

   // console.log(this.adminService.periodoActual)

  }

  cargarCobros() {
    this.cobros = [];
    for ( const cobro of this.adminService.cobros) {
      // console.log(cobro);
      this.cobros.push({numFactura: this.adminService.facturas[cobro.idFactura].numFactura,
          usuario: this.infoPadre(this.adminService.facturas[cobro.idFactura].idUsuario, 'nombre') + ' '
          + this.infoPadre(this.adminService.facturas[cobro.idFactura].idUsuario, 'apellidos'),
          dni: this.infoPadre(this.adminService.facturas[cobro.idFactura].idUsuario, 'dni'),
          fecha: cobro.fecha,
          fop: this.adminService.fops[cobro.fop].label,
          importe: cobro.importe,
        }
      );
    }
  }

  cargarFacturas() {
    this.facturas = [];
    for ( const factura of this.adminService.facturas) {
      this.facturas.push({numFactura: factura.numFactura,
          usuario: this.infoPadre(factura.idUsuario, 'nombre') + ' ' + this.infoPadre(factura.idUsuario, 'apellidos'),
          fechaFactura: factura.fechaFactura,
          total: factura.total,
          cobros: this.adminService.Cobrado(factura),
          id: factura.id,
          idLineaFactura: factura.idLineaFactura,
          idUsuario: factura.idUsuario,
          email: this.infoPadre(factura.idUsuario, 'email'),
          dni: this.infoPadre(factura.idUsuario, 'dni'),
        }
      );
    }
    // console.log(this.facturas)
    this.cargarPreFacturas()
  }
  onFacturar() {
    if (this.preFacturasSelected) {
      this.ordenFactura = 0 ;
      this.totalFacturas = this.preFacturasSelected.length;
      this.confirmFacturar()
      // this.displayDialog = true;
    }
  }

  confirmFacturar() {
    for (const factura of this.preFacturasSelected ) {
      this.adminService.creaFacturaFinal(factura, this.adminService.preLineasFactura.filter(
        linea => (factura.idLineaFactura.indexOf(linea.id) !== -1)), this.fechaFactura);
      this.ordenFactura++ ;
      console.log(this.ordenFactura);
    }
    this.adminService.facturasUpdate.next( this.adminService.facturas ) ;
    this.adminService.preparaFacturas(this.adminService.periodoActual);
    this.cargarPreFacturas();
    this.preFacturasSelected = [];
    //console.log(this.adminService.preFacturas);
    //console.log(this.preFacturasSelected);
  }

  abonarFactura(factID) {
    const abono = new Factura(0, this.adminService.facturas[factID].idUsuario, this.adminService.hoy());
    abono.total = this.adminService.Cobrado(this.adminService.facturas[factID]) * -1 ;
    // abono.totalIVA = this.adminService.facturas[factID].totalIVA * -1 ;
    abono.serie = 'A';
    abono.comentarios = 'Abono de factura: ' + this.adminService.facturas[factID].numFactura;
    let lineasAbono: Array<LineaFactura> ;
    lineasAbono = [] ;
    for (const idlinea of this.adminService.facturas[factID].idLineaFactura) {
      lineasAbono.push(this.adminService.lineasFactura[idlinea]);
      lineasAbono[lineasAbono.length - 1].importe = this.adminService.lineasFactura[idlinea ].importe  * abono.total / this.adminService.facturas[factID].total;
      lineasAbono[lineasAbono.length - 1].total = this.adminService.lineasFactura[idlinea ].total * abono.total / this.adminService.facturas[factID].total;
      // console.log('lineafactura importe' + lineasAbono[lineasAbono.length - 1].importe);
      // console.log('lineafactura total' + lineasAbono[lineasAbono.length - 1].total);
    }
    this.adminService.creaFacturaFinal( abono, lineasAbono, this.adminService.hoy(), true);
    const idcobro = this.adminService.cobros.length ;
    this.adminService.cobros.push(new Cobro( idcobro, factID , this.adminService.Cobrado(this.adminService.facturas[factID]), 4, '2018-12-31'));
    this.adminService.guardarObj(this.adminService.cobros[idcobro], 'facturacion/cobros');
    this.adminService.facturas[factID].cobros.push(idcobro);
    this.adminService.guardarObj(this.adminService.facturas[factID], 'facturacion/facturas');
    // 	F1700940 194
  }

  cargarPreFacturas() {
    this.preFacturas = [];
    //console.log(this.adminService.preFacturas);
    this.preFacturas = this.adminService.preFacturas;
    this.preFacturas = this.preFacturas.slice();
  }

  recuperaLineas(fact) {
    return this.adminService.lineasFactura.filter(linea => fact.idLineaFactura.indexOf( linea.id ) !== -1);
  }

  recuperaPreLineas(fact) {
    return this.adminService.preLineasFactura.filter(linea => fact.idLineaFactura.indexOf( linea.id ) !== -1);
  }

  cargarPeriodos() {
    this.periodos = [];
    for (const periodo of this.adminService.periodos) {
      this.periodos.push({label: periodo.descripcion, value: periodo.id})
    }
  }

  infoPadre(idPadre: string, atrib: string) {
    // console.log(idPadre)
    const padre = this.padreService.padres.find(alu => alu.key === idPadre);
    return padre[atrib];
  }

  onPeriodo() {
    this.adminService.preparaFacturas(this.periodoSelected);
    if ( this.adminService.periodos[this.periodoSelected].fechaFin < this.adminService.hoy()) {
      this.fechaFactura = this.adminService.periodos[this.periodoSelected].fechaFin;
    } else {
      this.fechaFactura = this.adminService.hoy();
    }
    this.cargarPreFacturas()
  }

  onRecibos() {
    this.recibos = this.adminService.prepararRecibos(this.periodoSelected);
  }

  expoRecibos() {
    for (const recibo of this.recibos) {
      const cobroTemp = new Cobro(this.adminService.cobros.length, recibo.idFactura, recibo.total, 3, this.adminService.facturas[recibo.idFactura].fechaFactura);
      this.adminService.cobros.push(cobroTemp);
      this.adminService.facturas[recibo.idFactura].cobros.push(cobroTemp.id );
      this.adminService.guardarObj(cobroTemp, 'facturacion/cobros');
      this.adminService.guardarObj(this.adminService.facturas[recibo.idFactura], 'facturacion/facturas');
    }
    this.recibos = [];
    //console.log('eureka')
  }
}
