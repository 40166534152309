import {Component} from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
        <div class="layout-footer clearfix">
          <span class="footer-text-left">
                <img alt="logo-colored" src="assets/layout/images/logo-slim.png" />
              <span>Intranet de Tecnoaprendo</span>
           </span>
            <span class="footer-text-right">
                <span class="material-icons">copyright</span>
                <span>All Rights Reserved</span>
            </span>
        </div>
    `,
    styles: ['.layout-footer img { width: 30px !important;} ']
})
export class AppFooterComponent {

}
