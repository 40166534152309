import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {UserService} from '../auth/user/user.service';
import {AlumnoService} from "./alumno.service";

@Component({
  selector: 'app-alumno',
  templateUrl: './alumno.component.html',
  styleUrls: ['./alumno.component.css']
})
export class AlumnoComponent implements OnInit {

  constructor( private inRoute: ActivatedRoute,
                public userService: UserService,
                public alumnoService: AlumnoService,
                private router: Router) { }

  ngOnInit() {

  }

  onNewStudent(){
    this.router.navigate(['new'], {relativeTo: this.inRoute});
  }

}
