import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Alumno} from '../../alumno/alumno.model';
import {AlumnoService} from "../../alumno/alumno.service";
import {Inscripcion} from '../../shared/inscripcion.model';

@Component({
  selector: 'app-tabla-alumnos',
  templateUrl: './tabla-alumnos.component.html',
  styleUrls: ['./tabla-alumnos.component.css']
})
export class TablaAlumnosComponent implements OnInit {
  @Output() kidSelected = new EventEmitter<{KidId: number}>();
  @Input() alumnos: Alumno;

  constructor( private alumnoService: AlumnoService ) { }

  ngOnInit() {
  }

  selectKid(i: number) {
    this.kidSelected.emit({KidId: i});
  }

  calcularTotal(inscripciones: Array<Inscripcion>) {
    let total = 0 ;
    for (const inscripcion of inscripciones) {
      total += inscripcion.total;
    }
    return total;

  }
}
