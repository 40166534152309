export class LineaFactura {
  public id: number;
  public idFactura: number;
  public idAlumno: string;
  public idServicio: number;
  public idHorario: number;
  public idPeriodo: number;
  public importe: number;
  public descuento: number;
  public descripcion: string;
  public IVAperc: number;
  public IVAamount: number;
  public total: number;
  public Q: number;
  public delete: boolean;

  constructor(id: number, idFactura: number, alumno: string, servicio: number, horario: number, periodo: number, importe: number, descuento: number, descripcion: string, Q: number = 1) {
    this.id = id;
    this.idFactura = idFactura;
    this.idAlumno = alumno;
    this.idServicio = servicio;
    this.idHorario = horario;
    this.idPeriodo = periodo;
    this.importe = importe;
    this.descuento = descuento;
    this.descripcion = descripcion;
    this.Q = Q;
    this.delete = false;
  this.total = (importe - (importe * descuento / 100)) * this.Q;
  }
}
