import { Component, OnInit } from '@angular/core';
import {Servicio} from "../servicio.model";
import {AdminService} from "../../admin.service";
import {Router} from '@angular/router';
import {Partner} from '../../partners/partner.model';
import {partition} from 'rxjs/operator/partition';

@Component({
  selector: 'app-lista-servicios',
  templateUrl: './lista-servicios.component.html',
  styleUrls: ['./lista-servicios.component.css']
})
export class ListaServiciosComponent implements OnInit {
  serviciosFiltrados: Array<Servicio> = [];
  filtroDescripcion: string = '';
  partners: Array<string>;

  constructor( private adminService: AdminService,
               private router: Router) { }

  ngOnInit() {
    this.partners = [];
    this.onFilter()
    this.adminService.serviciosUpdate.subscribe((servicios: Array<Servicio>) => this.onFilter());
    this.cargarPartners()
    this.adminService.partnerUpdate.subscribe( ( panas: Array<Partner>) => this.cargarPartners());
  }

  cargarPartners() {
    this.partners = []
    if (this.adminService.partners) {
      for (const panas of this.adminService.partners) {
        this.partners.push(panas.descripcion);
      }
    }
  }

  onFilter() {
    if (this.filtroDescripcion) {
      this.serviciosFiltrados = this.adminService.servicios.filter(servicio =>
        (servicio.descripcion.toLowerCase().indexOf(this.filtroDescripcion.toLowerCase(), 0) !== -1 ));
    } else {
      this.serviciosFiltrados = this.adminService.servicios
    }
    // console.log(this.adminService.servicios)
  }

  onRowSelect(event) {
    const servKey = this.adminService.servicios.findIndex(serv => serv.id === event.data.id)
    this.router.navigate(['/fichaservicio', servKey]);

  }
}
