import { Component, OnInit } from '@angular/core';
import {Alumno} from "../../../alumno/alumno.model";
import {ActivatedRoute, Params} from "@angular/router";
import {AlumnoService} from "../../../alumno/alumno.service";
import {Location} from "@angular/common";
import {User} from "../../../auth/user/user.model";
import {PadreService} from "../../padres/padre.service";
import {UserService} from "../../../auth/user/user.service";
import {SelectItem} from 'primeng/primeng';
import {AdminService} from '../../admin.service';

@Component({
  selector: 'app-form-alumno',
  templateUrl: './form-alumno.component.html',
  styleUrls: ['./form-alumno.component.css']
})
export class FormAlumnoComponent implements OnInit {
  id: number;
  idPadre: number
  alumno: Alumno;
  padre: User;
  colegios: SelectItem[];
  niveles: SelectItem[];

  constructor(private inRouter: ActivatedRoute,
              private alumnoService: AlumnoService,
              private location: Location,
              private padreService: PadreService,
              private adminService: AdminService) { }

  ngOnInit() {
    this.cargaColes();
    this.adminService.partnerUpdate.subscribe(cole => this.cargaColes());
    this.niveles = this.adminService.niveles;
    this.inRouter.params
      .subscribe(
        (params: Params) => {
          this.id = +params['id'];
          this.idPadre = +params['padre'];
          if (this.id < 0) {
            this.padre = this.padreService.padres[this.idPadre];
            this.alumno = new Alumno(this.padre.hijos.length, '', '', '', true, this.padre.key);
          } else {
            this.alumno = this.alumnoService.alumnos[this.id];
          }
        }
      );
  }

  cargaColes() {
    this.colegios = [];
    const coles = this.adminService.partners.filter(pana => pana.tipo === 1 );
    // console.log(coles);
    this.colegios.push({label: 'Indicar Colegio', value: -1 });
    for ( const cole of coles ) {
      this.colegios.push({label: cole.descripcion, value: cole.id})
    }
    this.colegios.push({label: 'Otro...', value: -2})
  }

  goBack() {
    this.location.back();
  }

  formPadre() {
    if (this.id < 0 ) {

      // Calculo el nivel por defecto
      this.alumno.nivel = this.alumnoService.calcularNivel(this.alumno);

      // Creo Alumno en la base de datos y recupero el ID de firebase
      const idAlu = this.padreService.newAlumno(this.alumno);

      // Añado el id del Alumno al padre y lo guardo
      this.padre.hijos.push(idAlu);
      this.padreService.guardarPadre(this.padre);
      console.log(this.alumno);
    } else {
      this.padreService.guardaAlumno(this.alumno);
    }
    this.goBack();
  }
}
