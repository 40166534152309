export class Recibo {
  constructor(public id?: number,
              public referencia?: string,
              public descripcion?: string,
              public titular?: string,
              public total?: number,
              public iban?: string,
              public idFactura?: number) {
  }
}
