import * as firebase from 'firebase';
import {User} from '../../auth/user/user.model';
import {Alumno} from '../../alumno/alumno.model';
import {Subject} from 'rxjs/Subject';
import {AlumnoService} from '../../alumno/alumno.service';
import {Injectable} from '@angular/core';
import {MessageService} from 'primeng/components/common/messageservice';
import {error} from 'util';

@Injectable()
export class PadreService {
  padresUpdate= new Subject();
  padres: User[];
  secondFire: any;
  padresImport: Array<any>;
  alumnosImport: Array<any>;
  produccion = true;
  permisos: Array<string> = ['Padre', 'Partner', '2', '3', '4', 'Profesor', '6', '7', '8', 'Admin', 'root'];

constructor(private alumnoService: AlumnoService,
            private messageService: MessageService) {

}

  loadPadres() {
    this.padres = [];
    firebase.database().ref('users/').on('value',
      (response) => {
        const resultado = response.val();
        for (const key in resultado) {
         // console.log(this.padres.find(padre => padre.key === key))
          let id: number;
          if ((!this.padres.find(padre => padre.key === key))) {
            id = this.padres.length;
            this.padres.push(resultado[key]);
            this.padres[id].key = key;
          } else {
            id = this.padres.findIndex(padre => padre.key === key);
            this.padres[id] = resultado[key];
          }
          if (!this.padres[id].hijos) {
            this.padres[id].hijos = [];
          }
          if ( (this.padres[id].admin) && !this.padres[id].idPermiso) {
            this.padres[id].idPermiso = 9;
          } else if (!this.padres[id].idPermiso) {
            this.padres[id].idPermiso = 0;
          }

        }
       // console.log(this.padres);
        this.padresUpdate.next(this.padres);
        // console.log('loadUser> Recibo datos: ' + this.datos);
      }
    );
  }

  startAdmin() {

    if (!this.secondFire) {
      if (this.produccion) {
      this.secondFire = firebase.initializeApp({
        apiKey: 'AIzaSyAK0Vb2S2d5nwRnwhD3Karw66oezeq7llo',
        authDomain: 'tecnoaprendo-pro.firebaseio.com',
        databaseURL: 'https://tecnoaprendo-pro.firebaseio.com',
      }, 'Secondary');
    } else {
        this.secondFire = firebase.initializeApp({
          apiKey: 'AIzaSyDhDSacayvCu4cE8s6-Dpak9jJGMeYTxH4',
          authDomain: 'tecno-aprendo.firebaseio.com',
          databaseURL: 'https://tecno-aprendo.firebaseio.com',
        }, 'Secondary');
      }
    }
  }

  crearPadre(padre: User , pwd ) {
  this.secondFire.auth().createUserWithEmailAndPassword(padre.email, pwd).then(firebaseUser => {
      // console.log( 'User ' + firebaseUser.user.uid + ' created successfully!');
      padre.key = firebaseUser.user.uid ;
      if (!padre.fechaAlta) {
        padre.fechaAlta = new Date().toLocaleDateString();
      }
      // I don't know if the next statement is necessary
      // this.secondFire.auth().signOut();
      //console.log(padre);
      this.guardarPadre(padre);
      return true
    })
      .catch( error => {
        this.messageService.add({severity: 'error', summary: 'Error', detail: error.message});
        console.log('error con: '+ padre.email + ' detalle: ' + error.message)}

      );
  }

  guardarPadre(datos: User) {
    // console.log(datos)
    //  console.log('saveUser> Gaurdando datos de Usuario:' + this.datos);
    firebase.database().ref('users/' + datos.key).set(datos);
  }

  newAlumno(alumno: Alumno) {
    const id = firebase.database().ref('alumnos/').push(alumno);
    alumno.key = id.key;
    this.guardaAlumno(alumno);
    return id.key;
  }

  guardaAlumno( alumno: Alumno ) {
    firebase.database().ref('alumnos/' + alumno.key).set(alumno);
    //  console.log('guardo hijo ' + id + ' con ID ' + hijo.id)
  }

  borrarAlumno( idAlumno , idPadre) {
    this.padres[idPadre].hijos.splice(
      this.padres[idPadre].hijos.findIndex(
        hijo => hijo === this.alumnoService.alumnos[idAlumno].key, 1));
    firebase.database().ref('alumnos/' + this.alumnoService.alumnos[idAlumno].key).remove(
      a => {
        if ( !a) {
          this.messageService.add({severity: 'success', summary: 'Confirmación', detail: 'El alumno ha sido eliminado correctamente'});
        }
      });
    this.alumnoService.alumnos.splice(idAlumno, 1);
    this.guardarPadre(this.padres[idPadre])
  }


  ImportarPadres() {
    this.padresImport = [
     /* {nombre: 'J'	,apellidos: 'Kosters'	,email: 'info+110@tecnoaprendo.es'	,telefono: ''	,codCli: '19'	,cuenta: ''	,titular: ''	,dni: ''	,marketing: true},
        {nombre: 'Padre Marc'	,apellidos: 'Vinent'	,email: 'info+120@tecnoaprendo.es'	,telefono: '666217353'	,codCli: '179'	,cuenta: ''	,titular: '30'	,dni: ''	,marketing: true},*/
    ];
/*  console.log(this.padresImport)
   // this.crearPadre(this.padresImport[1] ,this.padresImport[1].telefono )
    for (const padre of this.padresImport) {
      if (this.padres.find(dad=> dad.email === padre.email)) {
        console.log('Padre localizado, lo ignoro: '+padre.email)
      } else {
        this.secondFire.auth().signInWithEmailAndPassword(padre.email, (padre.telefono.length < 6) ? '456789' : padre.telefono)
          .then(
            response => {
              padre.key = this.secondFire.auth().currentUser.uid;
              if (!padre.fechaAlta) {
                padre.fechaAlta = new Date().toLocaleDateString();
              }
              console.log('Existe padre, lo guardo:')
              console.log(padre);
              this.guardarPadre(padre);
            })
          .catch(error => {
            console.log('PADRE NO LOGUEADO LO CREO:')
            console.log(padre);
            this.crearPadre(padre, (padre.telefono.length < 6) ? '456789' : padre.telefono)
          });
      }
    }*/
  }

  ImportarAlumnos() {
    this.alumnosImport = [
     /* {nombre: 'Joel'	,apellidos: 'Kosters'	,birthday: '2008-01-01'	,rrss: false	,email: 'info+110@tecnoaprendo.es'},
      {nombre: 'Sasha'	,apellidos: 'Beinsmann'	,birthday: '2011-01-01'	,rrss: false	,email: 'beisemann@monaco.mc'},*/
      ]

   /* for ( const alumno of this.alumnosImport) {

      if (this.padres.find(dad => dad.email === alumno.email) ) {
        let padre = this.padres.find(dad => dad.email === alumno.email);
        let pruebaAlumno = new Alumno(padre.hijos.length, alumno.nombre, alumno.apellidos, alumno.birthday, alumno.rrss, padre.key);
        if(pruebaAlumno.birthday.length>1) {
          pruebaAlumno.idNivel = this.calcularNivel(pruebaAlumno)
        } else {
          pruebaAlumno.idNivel = 0
        }
        if (!this.alumnoService.alumnos.find (alum => alumno.nombre === alum.nombre && alumno.apellidos === alum.apellidos)) {
            console.log('Alumno no existe lo creo: '+ alumno.email )
            padre.hijos.push(this.newAlumno(pruebaAlumno));
            this.guardarPadre(padre);

          }

      } else {
        console.log(alumno.nombre + ' ' + alumno.apellidos + ' ' + alumno.email )
      }

    }*/

  }

  getAge(alumno: Alumno) {
    const d = new Date(alumno.birthday);
    alumno.birthtime = d.getTime();
    const timeDiff = Math.abs(Date.now() - alumno.birthtime);
    return Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
  }
  calcularNivel(alumno: Alumno) {
    const edad = this.getAge(alumno);
    if ( edad < 9 ) {
      return 0;
    } else if (edad < 12) {
      return 1;
    } else {
      return 2;
    }
  }

}

