import { Router } from '@angular/router';
import * as firebase from 'firebase';
import {Injectable } from '@angular/core';
import {UserService} from './user/user.service';
import {NgForm} from '@angular/forms';


@Injectable()
export class AuthService {
  token: any;
  produccion = true;
  admin = false;
  public verPrint = true;

  constructor(private router: Router,
                private userService: UserService) {}

  start() {
    if (this.produccion) {
      firebase.initializeApp({
        apiKey: 'AIzaSyAK0Vb2S2d5nwRnwhD3Karw66oezeq7llo',
        authDomain: 'tecnoaprendo-pro.firebaseio.com',
        databaseURL: 'https://tecnoaprendo-pro.firebaseio.com',
      });
    } else {
      firebase.initializeApp({
        apiKey: 'AIzaSyDhDSacayvCu4cE8s6-Dpak9jJGMeYTxH4',
        authDomain: 'tecno-aprendo.firebaseio.com',
        databaseURL: 'https://tecno-aprendo.firebaseio.com',
      });
    }
    firebase.auth().onAuthStateChanged(
      User => {
        if (User) {
          this.token = User;
          this.userService.loadUser(User.uid);
          // console.log(User);
        } else  {
          this.token = null;
          this.admin = false;
          this.userService.uid = null
          this.userService.datos = null;
          this.userService.hijos = null;
          this.userService.admin = false;
          console.log ('no hay usuario logueado')
        }
      },
      Error => { console.log(Error)});
  }
  // this.authService.getToken()

  signupUser(f: NgForm) {
    firebase.auth().createUserWithEmailAndPassword(f.value.email, f.value.password)
      .then(User => {
        this.userService.newUser(User.user.uid, f);
        // console.log(User)
        this.router.navigate(['/']);
      })
      .catch(
        error => console.log(error)
      )

  }

  signinUser(email: string, password: string) {
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then(
        response => {
          firebase.auth().currentUser.getIdToken()
            .then(
              (token: string) => {
                this.token = token;
                // console.log('ejecuto signin')
                //this.router.navigate(['/']);
              })
        }

      )
      .catch(
        error => console.log(error)
      );
  }

  logout() {
    console.log('delogueo funcion')
    firebase.auth().signOut();
    this.router.navigate(['/signin']);
  }

  getToken() {
    firebase.auth().currentUser.getIdToken()
      .then(
        (token: string) => this.token = token
      );
    return this.token;
  }

  isAuthenticated() {
    return this.token != null;
  }

  isUserAuthenticated() {
    const autorizo = ((this.token != null) && (!this.userService.admin)) ? true : false;
    return autorizo;
  }

  isAdminAuthenticated() {
    const autorizo = ((this.token != null) && (this.userService.admin)) ? true : false;
    return autorizo;
  }

  isLoaded() {
    return this.userService.cargado;
  }
}
