import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Periodo} from '../periodo.model';
import {AdminService} from '../../admin.service';
import {Serie} from '../serie.model';

@Component({
  selector: 'app-series',
  templateUrl: './series.component.html',
  styleUrls: ['./series.component.css']
})
export class SeriesComponent implements OnInit {

  seriesFiltradas: Array<Serie> = [];
  filtroDescripcion: number ;
  alta = false;
  serieEdit: Serie;
  modal: any;
  displayDialog: boolean;


  constructor( private adminService: AdminService,
               private modalService: NgbModal) { }

  ngOnInit() {
    this.serieEdit = new Serie(-1, this.adminService.periodos[this.adminService.periodoActual].ejercicio, 'F');
    this.adminService.seriesUpdate.subscribe( serie => this.onFilter());
    this.onFilter()
  }

  onFilter() {
     if (this.filtroDescripcion) {
       this.seriesFiltradas = this.adminService.series.filter(seri =>
         (seri.ejercicio === this.filtroDescripcion ));
     } else {
       this.seriesFiltradas = this.adminService.series;
    }
  }

  onRowSelect(event) {
    const id = event.data.id;
    this.serieEdit = new Serie(this.seriesFiltradas[id].id,
      this.seriesFiltradas[id].ejercicio,
      this.seriesFiltradas[id].tipo,
      this.seriesFiltradas[id].contador);
    this.displayDialog = true;

  }

  guardarCambios() {
    this.alta = false;
    this.displayDialog = false;
    this.adminService.guardarObj(this.serieEdit, 'config/series');
  }

  nuevoItem() {
    this.alta = true ;
    this.serieEdit = new Serie(this.adminService.series.length, this.adminService.periodos[this.adminService.periodoActual].ejercicio, 'F');
    this.displayDialog = true;
  }

}
