import { Component, OnInit } from '@angular/core';
import {Partner} from '../partner.model';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AdminService} from '../../admin.service';
import {PadreService} from '../../padres/padre.service';
import {User} from '../../../auth/user/user.model';
import {MessageService} from 'primeng/components/common/messageservice';

@Component({
  selector: 'app-ficha-partner',
  templateUrl: './ficha-partner.component.html',
  styleUrls: ['./ficha-partner.component.css']
})
export class FichaPartnerComponent implements OnInit {

  id: number;
  partner = new Partner(-1, '');
  esperando = false;
  idUser: number;

  constructor(private inRouter: ActivatedRoute,
              private adminService: AdminService,
              private location: Location,
              private router: Router,
              private padreService: PadreService,
              private messageService: MessageService) { }

  ngOnInit() {
    this.padreService.padresUpdate.subscribe(
      padres => {
      this.activacionFinal();
      });
    if (!this.adminService.partners) {
      this.router.navigate(['/listapartners'])
      return false;
    }
    this.inRouter.params
      .subscribe(
        (params: Params) => {
          this.id = +params['id'];
          this.partner = this.adminService.partners[this.id];
          if ( this.partner.idUser) {
            this.idUser = this.padreService.padres.findIndex(padre => padre.key === this.partner.idUser)
          }
        }
      );
  }

  goBack() {
    this.location.back();
  }

  onFacturar() {
    if ( this.partner.email && this.partner.descripcion) {
      this.padreService.startAdmin();
      const padre = new User(this.partner.descripcion, '', '', this.partner.email);
      padre.idPermiso = 1;
      this.padreService.crearPadre( padre, '456789');
      this.esperando = true;
    } else {
      this.messageService.add({severity: 'error', summary: 'Faltan Datos', detail: 'E-Mail y Descripción son datos obligatorios'});
    }
  }

  activacionFinal() {
    if (this.esperando) {
      this.esperando = false;
      this.partner.facturacion = true;
      this.idUser = this.padreService.padres.findIndex(padre => padre.email === this.partner.email);
      console.log(this.idUser);
      console.log(this.padreService.padres[this.idUser])
      this.partner.idUser = this.padreService.padres[this.idUser].key;
      this.messageService.add({severity: 'success', summary: 'Activado', detail: 'Facturación activada correctamente'});
      console.log(this.partner)
      this.adminService.guardarObj(this.partner, 'partners');
    }
  }

}
