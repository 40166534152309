import {DireccionFacturacion} from '../../admin/facturas/direccion-facturacion.model';

export class User {
  public nombre: string;
  public apellidos: string;
  public telefono: string;
  public email: string;
  public marketing: boolean;
  public hijos: Array<string>;
  public admin: boolean;
  public key: string;
  public comments: string;
  public cuenta: string;
  public titular: string;
  public dni: string;
  public codCli: string;
  public fechaAlta: string;
  public direccion: string;
  public poblacion: string;
  public cp: string;
  public idPermiso: number;

  constructor(
    nombre = '',
    apellidos = '',
    telefono = '',
    email = '',
    marketing = true,
    hijos: Array<string> = [] ,
    comments: string = '',
    cuenta: string = '') {
    this.nombre = nombre;
    this.apellidos = apellidos;
    this.telefono = telefono;
    this.email = email;
    this.marketing = marketing;
    this.hijos = hijos;
//    this.key;
    this.comments = comments;
    this.admin = false;
    this.idPermiso = 0;
  }
}

