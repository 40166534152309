import {Component, OnInit} from '@angular/core';
import {AppComponent} from '../app.component';
import {UserService} from '../auth/user/user.service';
import {User} from '../auth/user/user.model';
import {AlumnoService} from '../alumno/alumno.service';
import {PadreService} from '../admin/padres/padre.service';
import {Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
     styles: ['.layout-topbar img { height: 35px !important; margin-top: 1px !important ;} ']
})
export class AppTopBarComponent implements OnInit {
  admin = false;
  datos: User;

  filtroBuscar: any[];
  seleccionBuscar: string[];
  busqueda: Array<any>;

    constructor(public app: AppComponent,
                private userService: UserService,
                private alumnoService: AlumnoService,
                private padreService: PadreService,
                private router: Router,
                private authService: AuthService) {

    }

    ngOnInit() {
      this.datos = new User();
      this.busqueda = [];
      this.userService.datosUpdate.subscribe( (data: User) => {
        this.datos = data;
        this.setMenu();
      });
      if ( this.userService.datos) {
        this.datos = this.userService.datos;
      }
      this.setMenu();
    }

    setMenu() {
      if (this.datos.admin) {
        this.padreService.padresUpdate.subscribe( padres => this.busqueda = this.recuperarBusqueda());
        this.alumnoService.alumnosUpdate.subscribe( alumnos => this.busqueda =  this.recuperarBusqueda());
        this.recuperarBusqueda()
      }

    }



  filtrarBusqueda(event) {
    this.filtroBuscar = [];
    this.filtroBuscar = this.busqueda.filter(
      alguien => ( alguien.nombre.toLowerCase().indexOf(event.query.toLowerCase()) !== -1 ||
      alguien.apellidos.toLowerCase().indexOf(event.query.toLowerCase()) !== -1));
    // console.log(this.filtroBuscar)
    }

  handleACDropdownClick(event: Event) {
    this.filtroBuscar = [];
    // mimic remote call
    setTimeout( a => this.filtroBuscar = this.recuperarBusqueda() , 100);
  }

  recuperarBusqueda() {
    this.busqueda = [];
    this.busqueda =  this.busqueda.concat( this.alumnoService.alumnos );
    this.busqueda = this.busqueda.concat( this.padreService.padres);
    // console.log(this.busqueda)
    return this.busqueda;
  }

  tipo(alguien) {
      if (alguien.padre) {
        return 'Alumno';
      } else {
        return 'Padre';

      }
  }

  eleccion(event) {
    if (event.padre) {
      this.router.navigate(['/fichaalumno', this.alumnoService.alumnos.findIndex(alu => alu.key === event.key)]);
      // return 'Alumno';
    } else {
      this.router.navigate(['/fichapadre', this.padreService.padres.findIndex(padre => padre.key === event.key)]);
      // return 'Padre';

    }
    this.seleccionBuscar = [];
     // console.log(event)

  }

  onLogout() {
    this.authService.logout();
  }
}
