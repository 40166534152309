import { Component, OnInit } from '@angular/core';
import {PadreService} from '../padre.service';
import {UserService} from '../../../auth/user/user.service';
import {User} from '../../../auth/user/user.model';
import {Router} from '@angular/router';
import {BreadcrumbService} from '../../../header/breadcrumb.service';
import {SelectItem} from 'primeng/primeng';

@Component({
  selector: 'app-lista-padres',
  templateUrl: './lista-padres.component.html',
  styleUrls: ['./lista-padres.component.css']
})
export class ListaPadresComponent implements OnInit {

  padresFiltrados: Array<User> = [];
  filtroApellidos: string = '';
  filtroNombre: string = '';
  padreSelected: User;
  permisos: SelectItem[];

  constructor( private padreService: PadreService,
               private userService: UserService,
               private router: Router,
               private breadcrumbSerivice: BreadcrumbService) {
    this.breadcrumbSerivice.setItems([
      {label: 'Usuarios'}]);
  }

  ngOnInit() {
    this.permisos = [];
    this.permisos.push({label: 'Todos', value: ''});
    for ( const idPermi in this.padreService.permisos) {
      if (this.padreService.permisos[idPermi].length > 1 ) {
        this.permisos.push({label: this.padreService.permisos[idPermi], value: +idPermi})
      }
    }
    // console.log(this.permisos)
    this.padreService.padresUpdate.subscribe(
      ( padresOrigen: Array<User>) => {
        this.onFilter(padresOrigen);
        // console.log(padresOrigen);
      }
    );
    this.onFilter();
  }


  onRowSelect(event) {
    const userKey = this.padreService.padres.findIndex(dad => dad.key === event.data.key)
    this.router.navigate(['/fichapadre', userKey]);

  }

  onFilter(padresOrigen: Array<User> = []) {
    if (padresOrigen.length === 0 ) {
      padresOrigen = this.padreService.padres;
    }
    if (this.filtroApellidos && this.filtroNombre) {
      this.padresFiltrados = padresOrigen.filter(dad =>
        (dad.nombre.toLowerCase().indexOf(this.filtroNombre.toLowerCase(), 0) != -1) &&
        (dad.apellidos.toLowerCase().indexOf(this.filtroApellidos.toLowerCase(), 0) != -1 ));
    } else if (this.filtroApellidos) {
      this.padresFiltrados = padresOrigen.filter(dad =>
        (dad.apellidos.toLowerCase().indexOf(this.filtroApellidos.toLowerCase(), 0) != -1 ));
    } else if (this.filtroNombre) {
      this.padresFiltrados = padresOrigen.filter(dad =>
        (dad.nombre.toLowerCase().indexOf(this.filtroNombre.toLowerCase(), 0) != -1 ));
    } else {
      this.padresFiltrados = padresOrigen
    }
  }

}
