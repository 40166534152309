import { Component, OnDestroy } from '@angular/core';
import { AppComponent } from '../app.component';
import { BreadcrumbService } from './breadcrumb.service';
import { Subscription } from 'rxjs/Subscription';
import {MenuItem, Message} from 'primeng/primeng';
import {AuthService} from '../auth/auth.service';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './app.breadcrumb.component.html'
})
export class AppBreadcrumbComponent implements OnDestroy {

    subscription: Subscription;
    msgs: Message[] = [];
    items: MenuItem[];

    constructor(public breadcrumbService: BreadcrumbService,
                public authService: AuthService) {
        this.subscription = breadcrumbService.itemsHandler.subscribe(response => {
            this.items = response;
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

  onLogout() {
    this.authService.logout();
  }
}
