import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Params} from '@angular/router';
import {Partner} from '../partner.model';
import {AdminService} from '../../admin.service';

@Component({
  selector: 'app-form-partner',
  templateUrl: './form-partner.component.html',
  styleUrls: ['./form-partner.component.css']
})
export class FormPartnerComponent implements OnInit {
  id: number;
  partner: Partner;

  constructor(private inRouter: ActivatedRoute,
              private location: Location,
              private adminService: AdminService) { }

  ngOnInit() {
    this.inRouter.params
      .subscribe(
        (params: Params) => {
          this.id = +params['id'];
          if (this.id < 0) {
            this.partner = new Partner(this.adminService.partners.length,'');
          } else {
            this.partner = this.adminService.partners[this.id];
          }

        }
      );
  }

  goBack() {
    this.location.back();
  };

  formPartner() {
    this.adminService.guardarObj(this.partner, 'partners')
    this.goBack();
  }
}


