import * as firebase from 'firebase';
import {Alumno} from 'app/alumno/alumno.model';
import {Inscripcion} from '../shared/inscripcion.model';
import {Subject} from 'rxjs/Subject';
import {Horario} from './calendar/horario.model';
import {Servicio} from '../admin/servicios/servicio.model';

export class AlumnoService {
  alumnosUpdate = new Subject();
  alumnos: Array<Alumno>;
  nivel: Array<string> = ['MiniHacker', 'HackerJunior', 'HackerScout']


  loadAlumnos() {
    this.alumnos = [];
    firebase.database().ref('alumnos/').on('value',
      (response) => {
        const resultado = response.val();
        for ( const key in resultado) {
          if (!this.alumnos.find(alu => alu.key === key )) {
            const id = this.alumnos.length;
             // console.log(id + ' - ' + key);
            this.alumnos.push(resultado[key]);
            if (!this.alumnos[id].inscripciones) {
              this.alumnos[id].inscripciones = [];
            } else {
              for ( const insKey in this.alumnos[id].inscripciones) {
                if ( this.alumnos[id].inscripciones[insKey].nivel ) {
                  this.alumnos[id].inscripciones[insKey].idNivel = this.nivel.findIndex(
                    nivel => nivel === this.alumnos[id].inscripciones[insKey].nivel);
                  this.alumnos[id].inscripciones[insKey].nivel = null;
                }if (!(this.alumnos[id].inscripciones[insKey].idNivel >= 0 ) ) {
                  this.alumnos[id].inscripciones[insKey].idNivel = 0
                }
                if ( this.alumnos[id].inscripciones[insKey].nivel === '' ) {
                  this.alumnos[id].inscripciones[insKey].nivel = null;
                }
                this.alumnos[id].inscripciones[insKey].id = +insKey;
              }
            }
            if (this.alumnos[id].key === '') {
              this.alumnos[id].key = key;
            }
            if ( this.alumnos[id].nivel ) {
              this.alumnos[id].idNivel = this.nivel.findIndex(nivel => nivel === this.alumnos[id].nivel);
              this.alumnos[id].nivel = null;
            }
            if ( this.alumnos[id].nivel === '') {
              this.alumnos[id].nivel = null;
            }
           // console.log(resultado[key]);
          }
        }
        this.alumnosUpdate.next(this.alumnos);
      }
    );
  }

  updateMatricula(aluKey: number, inscripcion: Inscripcion, add: boolean, del: boolean = false) {
    if (add) {
      this.alumnos[aluKey].inscripciones.push(inscripcion);
    } else if (del) {
      this.alumnos[aluKey].inscripciones.splice(
        this.alumnos[aluKey].inscripciones.findIndex(
          matr =>
            (matr.nivel === inscripcion.nivel) && (matr.idHorario === inscripcion.idHorario )),
        1);
    } else {
      this.alumnos[aluKey].inscripciones[this.alumnos[aluKey].inscripciones.findIndex(inscrip =>
        (inscrip.idServicio === inscripcion.idServicio) && (inscrip.idHorario === inscripcion.idHorario))] = inscripcion;
    }
    firebase.database().ref('alumnos/' + this.alumnos[aluKey].key + '/inscripciones').set(this.alumnos[aluKey].inscripciones);
  }

  getAge(alumno: Alumno) {
    const d = new Date(alumno.birthday);
    alumno.birthtime = d.getTime();
    const timeDiff = Math.abs(Date.now() - alumno.birthtime);
    return Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
  }

  calcularEdad(nacimiento: string) {
    const d = new Date(nacimiento);
    const timeDiff = Math.abs(Date.now() - d.getTime());
    return ((timeDiff / (1000 * 3600 * 24)) / 365).toFixed(2);
  }

  calcularNivel(alumno: Alumno) {
    const edad = this.getAge(alumno);
    if ( edad < 9 ) {
      return 'MiniHacker';
    } else if (edad < 12) {
      return 'HackerJunior';
    } else {
      return 'HackerScout';
    }
  }

    calcularTotal(inscripciones: Array<Inscripcion>) {
      let total = 0 ;
      const hoy = new Date().toISOString().substr(0 , 10);
      for (const inscripcion of inscripciones) {
        if ((inscripcion.fechaInicio <= hoy) && (inscripcion.fechaFin >= hoy)) {
          total += inscripcion.total;
        }

      }
      return total;

    }

  disponibilidadTemporal(clase: Horario, servicio: Servicio) {
    if (servicio.puntual) {
      return servicio.alumnos.length;
    } else {
      // Recupero las Keys de las clases, y busco las inscripciones que a fecha de hoy sigan vigentes
      return clase.alumnos.filter(
        aluKey => this.alumnos.find( alumno => alumno.key === aluKey).inscripciones.find(
          inscripcion => inscripcion.idServicio === servicio.id && inscripcion.idHorario === clase.id &&
            (new Date(inscripcion.fechaFin).getTime() > Date.now() )) ).length;
    }
  }

  activoClase( aluKey: string, idClase: number, idServicio: number, puntual: boolean = false) {
    if (puntual) {
      return ( this.alumnos.find(alu => alu.key === aluKey).inscripciones.find(
        inscripcion => inscripcion.idServicio === idServicio &&
          (new Date(inscripcion.fechaFin).getTime() > Date.now() )) );
    } else {
      return ( this.alumnos.find(alu => alu.key === aluKey).inscripciones.find(
        inscripcion => inscripcion.idServicio === idServicio && inscripcion.idHorario === idClase &&
          (new Date(inscripcion.fechaFin).getTime() > Date.now() )) );
    }
  }
}
