import { Component, OnInit } from '@angular/core';
import { NgForm} from "@angular/forms";
import {UserService} from "./user.service";
import {User} from "./user.model";


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  public msgs = [];

  user = new User("","","","");
  constructor( private userService: UserService) { }

  ngOnInit() {
    this.user=this.userService.getUserData();
    //console.log(this.userService.email);
  }

  onChangeUser(f: NgForm  ) {
    this.userService.updateUser(f.value.nombre, f.value.apellidos,f.value.telefono,f.value.marketing);
    this.msgs.push({severity:'success', summary:'Guardado: ', detail:'Ok'});
    setTimeout(()=> this.msgs = [], 4000 );
  }
}
