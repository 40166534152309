import { Component, OnInit } from '@angular/core';
import {Alumno} from '../../../alumno/alumno.model';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AlumnoService} from '../../../alumno/alumno.service';
import {Location} from '@angular/common';
import {CalendarService} from '../../../alumno/calendar/calendar.service';
import {Inscripcion} from '../../../shared/inscripcion.model';
import {AdminService} from '../../admin.service';
import {Servicio} from '../../servicios/servicio.model';
import {UserService} from '../../../auth/user/user.service';
import {PadreService} from '../../padres/padre.service';
import {BreadcrumbService} from '../../../header/breadcrumb.service';
import {SelectItem} from 'primeng/primeng';
import {MessageService} from 'primeng/components/common/messageservice';


@Component({
  selector: 'app-ficha-alumno',
  templateUrl: './ficha-alumno.component.html',
  styleUrls: ['./ficha-alumno.component.css']
})

export class FichaAlumnoComponent implements OnInit {
  id: number;
  idPadre: number;
  alumno: Alumno = new Alumno(-1, '', '', '', false, '');
  servicioSelected = -1;
  displayDialog: boolean;
  servicioTemp: Servicio;
  claseTemp: any;
  inscripcionTemp = new Inscripcion(0, 0, 0)
  alta: boolean;
  facturado: boolean;
  servicios: SelectItem[];
  oldInscripciones: Array<Inscripcion>;
  puntual = false;


  constructor(private inRouter: ActivatedRoute,
              public alumnoService: AlumnoService,
              private location: Location,
              private calendarService: CalendarService,
              public adminService: AdminService,
              private router: Router,
              public userService: UserService,
              private padreService: PadreService,
              private breadcrumbService: BreadcrumbService,
              private messageService: MessageService) {

    this.breadcrumbService.setItems([
      {label: 'Lista Alumnos', routerLink: ['/listaalumnos']},
      {label: 'Ficha Alumno'}
    ]);
  }




  ngOnInit() {
    this.alumnoService.alumnosUpdate.subscribe( alu => this.cargarOldInscripciones());
    this.adminService.serviciosUpdate.subscribe(servicios => this.cargarServicios());
    this.claseTemp = this.adminService.servicios[0].horario[0];
    if (!this.alumnoService.alumnos) {
      this.router.navigate(['/listaalumnos']);
      return false;
    }

    this.inRouter.params
      .subscribe(
        (params: Params) => {
          this.id = +params['id'];
          this.cargaAlumno();
        }
      );
  }

  cargarServicios() {
    this.servicios = [];
    for ( const servi of this.adminService.servicios ) {
      if ( ( (this.adminService.partners[servi.idLugar].tipo !== 1) ||  (this.adminService.partners[servi.idLugar].tipo === 1
          && this.alumno.idColegio === servi.idLugar) ) && (servi.fechaFin >= this.adminService.hoy() )) {
        this.servicios.push({label: servi.descripcion + this.calculaSesion(servi), value: servi.id});
        if (this.servicioSelected < 0 && servi.idNivel === this.alumno.idNivel) {
          this.servicioSelected = servi.id;
        }
      }
    }
  }

  cargaAlumno() {
    this.alumno = this.alumnoService.alumnos[this.id];
    // console.log(this.alumno)
    this.servicioSelected = this.alumno.idNivel;
    this.idPadre = this.padreService.padres.findIndex(padre => padre.key === this.alumno.padre);
    this.cargarServicios();
    this.cargarOldInscripciones();
  }

  cargarOldInscripciones() {
    this.oldInscripciones = [];
    this.oldInscripciones = this.alumno.inscripciones.filter(inscrip => (new Date(inscrip.fechaFin).getTime() < Date.now() ));
  }

  borrarAlumno() {
    if (this.alumno.inscripciones.length > 0) {
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'No se puede eliminar, porque el Alumno tiene inscripciones'});
    } else {
      if (confirm('Estas seguro que quieres borrar este alumno?')) {
        this.padreService.borrarAlumno(this.id, this.idPadre);
        this.router.navigate(['/listaalumnos']);
      }
    }
  }

  goBack() {
    this.location.back();
  }

  onDaySelect(classData: {ClassId: number}) {
    this.servicioTemp = this.adminService.servicios[this.servicioSelected];
    this.puntual = this.servicioTemp.puntual
    this.claseTemp = this.servicioTemp.horario[classData.ClassId];
    const servicio = this.adminService.servicios[this.servicioSelected];
    const clase = servicio.horario[classData.ClassId];

    // console.log(clase)
    // console.log(classData.ClassId)
    // console.log(servicio)

    // Creo la ventana modal-inscripciones
    if ( !this.alumnoService.activoClase( this.alumno.key, clase.id, this.servicioSelected, this.puntual) ) {
      if ( (this.alumnoService.disponibilidadTemporal(clase, servicio) < servicio.capacidad) ||
        (this.alumnoService.disponibilidadTemporal(clase, servicio) >= servicio.capacidad &&
          confirm('La clase esta llena, deseas forzar la inscripcion')) ) {
        const servi = this.adminService.servicios[this.servicioSelected];
        this.abrirModal( new Inscripcion(servicio.idNivel,
          +this.servicioSelected,
          clase.id,
          servi.precio,
          servi.fechaFin,
          ((new Date(servi.fechaInicio).getTime() > Date.now() ) ? servi.fechaInicio : new Date().toISOString().substr(0 , 10 ))
          ), true);
      }
    } else {
       // Recupero Inscripcion
      if (this.puntual) {
        this.abrirModal(this.alumno.inscripciones.find(inscrip =>
          (inscrip.idServicio === this.servicioSelected) &&
          (new Date(inscrip.fechaFin).getTime() > Date.now() )));
      } else {
        this.abrirModal(this.alumno.inscripciones.find(inscrip =>
          (inscrip.idServicio === this.servicioSelected) && (inscrip.idHorario === clase.id) &&
          (new Date(inscrip.fechaFin).getTime() > Date.now() )));
      }
    }
    // console.log(this.inscripcionTemp)
  }

  onOldSelect(event) {
     this.abrirModal(this.alumno.inscripciones.find( inscrip => inscrip.id === event.data.id ) );
  }

  abrirModal(inscripcion: Inscripcion, alta: boolean = false) {
    this.alta = alta;
    this.inscripcionTemp = inscripcion;
    // confirmo si el servicio esta facturado ya que si lo esta no puedo eliminarlo, solo modificarlo.
    if (this.adminService.lineasFactura.find(linea =>
        (linea.idServicio === this.inscripcionTemp.idServicio &&
          linea.idHorario === this.inscripcionTemp.idHorario &&
          linea.idAlumno === this.alumno.key))) {
      this.facturado = true;
    } else {
      this.facturado = false;
    }
    this.displayDialog = true;
  }

  guardarInscripcion(del: boolean = false) {
    this.inscripcionTemp.total = this.inscripcionTemp.precio - (this.inscripcionTemp.precio * this.inscripcionTemp.descuento / 100)
    this.alumnoService.updateMatricula(this.id, this.inscripcionTemp, this.alta, del);
    if (this.alta || del ) {
      this.adminService.updateHorario(this.alumno.key, this.inscripcionTemp, this.alta);
    }
    this.displayDialog = false;
  }

  calculaSesion(servicio) {
    const result = this.alumno.inscripciones.filter(inscrip => (inscrip.idServicio === servicio.id)).length
    return (result > 0 ) ? ' (' + result + ')' : '' ;
  }
}
